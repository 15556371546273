import React from "react";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import CloseButton from "./Close";


const Terms = ({ hideModal, customStyles }) => {
    const { t, i18n } = useTranslation()
    return (
        <ReactModal isOpen style={customStyles}>
            <div className="relative text-x-gray2 p-8">
                <div className="absolute right-4 top-4 cursor-pointer" onClick={hideModal}><CloseButton /></div>
                <h1 className="text-[2rem] mb-8 text-center">{t('Modal-Terms-h1')}</h1>
                <div className="text-sm px-4">
                    {i18n.resolvedLanguage === 'en' && <div>
                    <p>
                        The Xfinity Rewards Program ("Program") is a rewards program offered by
                        Comcast Cable Communications Management, LLC ("Xfinity" or
                        &#8220;Comcast&#8221;) that provides Members certain Rewards. These
                        General Terms and Conditions and any additional terms associated with
                        specific Rewards (collectively, &#8220;Terms&#8221;) apply to the
                        Program. By participating in the Program, you (&#8220;you&#8221; or
                        &#8220;your&#8221;) agree to these Terms.
                    </p>
                    <p>
                        1. <u>Eligibility.</u> To be eligible for the Program you must:
                    </p>
                    <p>
                        (a) Be a current Xfinity post-pay TV or Internet, or Wireline Voice
                        residential customer with a current and active Xfinity account in
                        good-standing (&#8220;Account&#8221;);
                    </p>
                    <p>
                        (b) Be 18 years of age or older and a legal resident of one of the fifty
                        (50) United States or the District of Columbia; and
                    </p>
                    <p>
                        (c) Enroll in the Program by following the procedures under Section 2.
                    </p>
                    <p>
                        Employees who receive Xfinity courtesy services are not eligible to
                        participate in the Program with respect to the address at which they
                        receive Xfinity courtesy services. Employees, officers, directors, agents
                        and representatives of Xfinity and its affiliates who are eligible and
                        enroll in the Program may not be eligible for all Rewards. The Program is
                        limited to one (1) Account per customer/household and may not be
                        transferred. If a Member has multiple Accounts, the Account with the
                        Member&#8217;s longest Tenure shall be deemed to be the Program Account,
                        but Rewards may be applied to the other Accounts. Customers who only
                        receive Xfinity services through bulk or similar accounts, agreements, or
                        arrangements through which services are paid by a third party, are not
                        eligible to participate in the Program unless such customers have
                        established individually billed Accounts and are purchasing a required
                        service(s) under that Account. Members who redeem certain Rewards may be
                        ineligible to redeem additional identified Rewards within specified
                        periods.
                    </p>
                    <p>
                        2. <u>Enrollment.</u> To enroll in the Program, you must (1) visit
                        Xfinity.com/Rewards via your web browser or through the Xfinity App, (2)
                        login using your Xfinity credentials and (3) click to enroll in the
                        Program. By clicking to enroll you are accepting the Program Terms.
                        Eligible Xfinity customers who enroll in the Program are referred to herein
                        as &#8220;Members&#8221; or a &#8220;Member&#8221;. The Program is for your
                        personal use and may not be used for any business dealings or other
                        commercial purpose.
                    </p>
                    <p>
                        3. <u>Program Tiers.</u> There are four (4) tiers (&#8220;Tier(s)&#8221;)
                        of membership in the Program: Silver, Gold, Platinum, and Diamond. Each
                        Tier and Rewards made available for each Tier will correspond to the amount
                        of time a Member&#8217;s Account remains continuously active and in
                        good-standing, as determined by Xfinity in its sole discretion (that is
                        your &#8220;Tenure&#8221;).
                    </p>
                    <p>
                        (a) As your Tenure increases and you advance Tiers, you will be eligible
                        for more Rewards.
                    </p>
                    <p>
                        (b) Your initial Tenure will be determined by the date on which you
                        established your current Account with Xfinity.
                    </p>
                    <p>
                        (c) If all of your eligible Xfinity services are for any reason ended, you
                        will not be eligible to receive Rewards. If you reinstate an eligible
                        service under an Account with the same Xfinity credentials you used to
                        enroll in the Program within 90 days of ending your services, your Tenure
                        status at the time of ending your services shall be reinstated. If you do
                        not so reinstate an eligible service within the 90 day period, you will be
                        automatically removed from the Program. You may not redeem Rewards once you
                        cease to be a Member.
                    </p>
                    <p>
                        Each Tier will unlock certain benefits for Members to enjoy in the Program.
                        Tier thresholds are solely determined by Xfinity and are subject to change.
                        To view the current Tier thresholds, click <a href="#" className="underline">Xfinity.com/Rewards</a>
                    </p>
                    <p>
                        4. <strong>Rewards:</strong> Rewards may include access to special events,
                        limited-time offers, sweepstakes opportunities, product discounts, and
                        other Rewards as may be established by Xfinity and offered under the
                        Program from time to time. Rewards are referred to in these Terms as
                        &#8220;Rewards&#8221; or a &#8220;Reward&#8221;.
                    </p>
                    <p>
                        The Rewards available to a Member at any given time will depend on that
                        Member&#8217;s Tier level and are only available while supplies last.
                        Rewards may be changed at any time by Xfinity. Rewards may have additional
                        terms that apply, including without limitation, additional terms from any
                        third-party company supplying the Reward (&#8220;Rewards
                        Partner(s)&#8221;), an expiration date or other conditions. Members may be
                        required to share information with Xfinity or any Rewards Partner in order
                        to receive that Reward. Xfinity is not responsible for the acts or
                        omissions of any Rewards Partner, including for the fulfillment of any
                        Rewards. The information provided to the Rewards Partner to claim or use a
                        Reward is subject to the privacy policy of the Rewards Partner. Only the
                        primary Account owner of the Member&#8217;s Account can claim and use
                        Rewards. Members can check their individual Account for Reward details,
                        including availability, claim instructions, information about expiration
                        dates that may apply, and order status by visiting Xfinity.com/Rewards via
                        your web browser or through the Xfinity App login using your Xfinity
                        credentials.
                    </p>
                    <p>
                        Rewards have no cash value (unless required by law) and cannot be sold,
                        resold, returned exchanged, or substituted, except by Xfinity who may in
                        its sole discretion substitute Rewards at any time. Xfinity is not
                        responsible for lost, stolen or damaged Rewards. If a Member lists a Reward
                        for sale, or sells a Reward, the Reward may be forfeited and voided.
                        Xfinity is not responsible for any taxes incurred by Members in connection
                        with Rewards. Xfinity may be required to report taxable amounts related to
                        Rewards to appropriate taxing authorities. Some Rewards may only be
                        available to residents of a particular area or state. Rewards are subject
                        to change, alteration, substitution, or termination by Xfinity in its sole
                        discretion at any time.
                    </p>
                    <p>
                        Unless otherwise stated as part of a Reward, transportation to/from a
                        Reward event will not be included. Rewards event dates, times and seating
                        are determined at Xfinity&#8217;s sole discretion and are subject to
                        change. If a Member or their guest(s) cannot attend an event on the
                        specified date, the Reward will be forfeited. Xfinity is not liable for
                        replacing any lost, mutilated, or stolen tickets to Rewards events. If any
                        Reward event, or any events in connection with it, do not take place as
                        scheduled, or are cancelled for any reason, including due to Acts of God
                        (such as, but not limited to, fires, explosions, earthquakes, and floods),
                        acts of terrorism, epidemic, pandemic, civic disturbances, work stoppage or
                        any other natural disaster (&#8220;Force Majeure Event&#8221;), the Reward
                        will not be offered and no substitution or compensation will be provided.
                    </p>
                    <p>
                        Individuals must be twenty-one (21) years or older to drink alcohol at any
                        Reward event(s). Rewards events and tickets will be available in limited
                        quantities on a first-come, first-served basis while supplies last. Each
                        Rewards event and Rewards ticket will have its own terms that apply.
                        Members and their guest(s) agree to comply with all applicable event and
                        ticket terms, rules and regulations, including, but not limited to, event
                        venue regulations. Xfinity and the applicable venue for the Reward event
                        reserve the right to deny entry to, or remove Members and their guest(s)
                        from the venue if Members or their guest(s) engage in disruptive behavior,
                        with no further compensation or liability of any kind to Members and their
                        guest(s) (as applicable). Participation in the Program, including the use
                        of a ticket and/or attendance at a Reward event and related events
                        constitutes a Member&#8217;s permission for Xfinity and its designees to
                        use Members&#8217; and their guests&#8217; name(s), recordings, comments
                        and/or likenesses and photos in advertisements and social media for
                        purposes of advertising and trade without further compensation to Members
                        and their guest(s), unless prohibited by law.
                    </p>
                    <p>
                        Rewards may fall under a number of categories, including, but not limited
                        to, the following:
                    </p>
                    <p>
                        (a) <strong>Surprise and Delight Rewards:</strong> Surprise and Delight
                        Rewards are limited to Members as of a certain date and provided without
                        prior notice. Surprise and Delight Rewards may include, but are not limited
                        to, ticket opportunities for certain music, shows, entertainment and
                        sporting events located throughout the United States. Tickets can also
                        include special access to experiences. Each Surprise and Delight Reward
                        will have its own terms that apply.
                    </p>
                    <p>
                        (b) <strong>Offers and Discounts:</strong> Rewards may include access to
                        limited time offers and discounts for Xfinity products and services, as
                        well as offers and/or discounts from our third-party partners. Each offer
                        or discount will have its own terms that apply.
                    </p>
                    <p>
                        (c)<strong> Sweepstakes: </strong>Rewards may include the opportunity to
                        participate in random drawing sweepstakes (&#8220;Sweepstakes&#8221;). Each
                        Sweepstakes will have its own official rules and will have its own start
                        date, end date, prize(s) and entry conditions.
                    </p>
                    <p>
                        5. <strong>Communications with Xfinity and Privacy:</strong> By becoming a
                        Member in the Program, Members agree to receive advertising, marketing
                        materials and other communications from Xfinity related to the Program and
                        will automatically be subscribed to receive Program emails. Members agree
                        that Xfinity may send them transactional messages and information about the
                        Program via email, mail, text or push notifications to their mobile device.
                        If Members do not wish to receive non-transactional communications, Members
                        can request these communications be discontinued by visiting the Xfinity
                        Communications Preference Center at Xfinity.com/Preferences. Changes,
                        Termination and/or Removal From Program: Xfinity may, in its sole
                        discretion, alter, limit, and/or modify, these Terms, its website, Program
                        rules, regulations, Rewards, benefits, eligibility for becoming a Member,
                        and/or any other feature of the Program (including assigning any of its
                        obligations to customers under the Program at any time to another person or
                        entity, without recourse), or may terminate the Program at any time at its
                        sole discretion, with or without notice. It is Members&#8217;
                        responsibility to check and review these Terms from time to time to keep
                        informed of any changes. By joining the Program, Members agree to be bound
                        by all such changed Terms.
                    </p>
                    <p>
                        If a Member terminates their Account, a Member ends all of their eligible
                        services (subject to the provisions under Section 3), a Member&#8217;s
                        Account is no longer considered to be in good-standing, or a Member&#8217;s
                        Program participation otherwise terminates, that Member will forfeit any
                        unused Rewards or benefits available. Xfinity may monitor Members&#8217;
                        Program participation and activity for compliance with these Terms. Xfinity
                        may suspend or terminate a Member&#8217;s Program participation at any
                        time. Members may lose benefits or the ability to claim new benefits in the
                        Program if their Account status changes, they fail to make payments on
                        their Account, if Xfinity suspects fraudulent activity, the Member sells
                        any Rewards received from the Program, or Xfinity makes changes to the
                        Program.
                    </p>
                    <p>
                        Xfinity reserves the right to exclude individuals from the Program in its
                        sole but reasonable discretion. In particular, any abuse, manipulation or
                        "gaming" of the Program or these Terms (as determined by Xfinity), failure
                        to follow any terms of the Program, any misrepresentation or any conduct
                        detrimental to the interests of Xfinity not otherwise protected by law may
                        subject Members to Membership revocation or deduction of Tier status
                        obtained through the activities described in this section and may affect
                        eligibility for further participation in the Program. Membership is
                        non-transferable and Membership claims and redemptions must be made by the
                        Member. Except as provided in Section 3, if a Membership is revoked or
                        otherwise cancelled, any Tier status in the applicable Program Account will
                        automatically expire.
                    </p>
                    <p>
                        7. <strong>Limitation of Liability; Releases:</strong>
                    </p>
                    <p>
                        ALL REWARDS ARE PROVIDED &#8220;AS IS&#8221; WITH NO GUARANTEES. ALL
                        WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE WARRANTIES OF
                        MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, ARE HEREBY DISCLAIMED
                        BY COMCAST.
                    </p>
                    <p>
                        Neither Xfinity nor any ticket venue or Rewards Partner shall have any
                        responsibility or liability whatsoever for any property loss, damage,
                        personal injury, or death, in connection with any Reward provided as part
                        of the Program and/or any person's participation in any Reward event or
                        related events. When a Member claims and/or uses a Reward or otherwise
                        participates in the Program, the Member releases Xfinity, its parents,
                        affiliates and subsidiaries, all Rewards Partners, and their officers,
                        employees and agents from all claims, actions, causes of actions and
                        damages, including without limitation, claims of personal injury, including
                        death, property damage, and infringement of rights of publicity, arising
                        out of the use of Rewards, or in connection with participation in Rewards
                        events or the Program. Xfinity and Members agree to limit claims against
                        each other for damages or other monetary relief to direct damages only.
                        This limitation and waiver will apply regardless of the theory of
                        liability. This limitation of liability also applies if a Member brings a
                        claim against any Rewards Partner, to the extent Xfinity would be required
                        to indemnify such company against any claims. Members agree that Xfinity is
                        not responsible for problems caused by any Rewards Partner or an event
                        venue, Member, others, or by any Force Majeure Event.
                    </p>
                    <p>
                        8. These Terms incorporate and supplement the Comcast Agreement for
                        Residential Services, Additional Xfinity Internet &amp; Xfinity Voice
                        Terms, and Comcast Xfinity Customer Privacy Notice, and in the event of a
                        conflict with these Terms, the Comcast Agreement for Residential Services,
                        Additional Xfinity Internet &amp; Xfinity Voice Terms, and Comcast Xfinity
                        Customer Privacy Notice shall control.
                    </p>
                    <p>
                        <strong>Additional Information on the Program:</strong>
                        To address any questions regarding the Program, visit <a href="#" className="underline">Xfinity.com/Rewards</a>
                    </p>

                    </div>}

                    {i18n.resolvedLanguage === 'es' && <div>
                        <p>
                            El Programa de Xfinity Rewards (&#8220;Programa&#8221;) es un programa
                            de recompensas ofrecido por Comcast Cable Communications Management,
                            LLC ("Xfinity" o &#8220;Comcast&#8221;) que proporciona ciertas
                            recompensas a sus miembros. Estos T&#233;rminos y condiciones generales
                            y cualquier t&#233;rmino adicional asociado con recompensas
                            espec&#237;ficas (en conjunto, &#8220;T&#233;rminos&#8221;) se aplican
                            al Programa. Al participar en el Programa, usted (&#8220;usted&#8221; o
                            &#8220;su(s)&#8221;) acepta estos T&#233;rminos.
                        </p>
                        <p>
                            1. <u>Elegibilidad.</u> Para ser elegible para el programa, usted debe:
                        </p>
                        <p>
                            (a) Ser cliente residencial actual de Xfinity TV o Internet pospago, o
                            Wireline Voice con una cuenta de Xfinity actualizada y activa al d&#237;a
                            en sus pagos (&#8220;Cuenta&#8221;);
                        </p>
                        <p>
                            (b) Tener 18 a&#241;os o m&#225;s y ser residente legal de uno de los
                            cincuenta (50) Estados Unidos o el Distrito de Columbia; y
                        </p>
                        <p>
                            (c) Inscr&#237;bete en el Programa siguiendo los procedimientos que
                            aparecen bajo la Secci&#243;n 2.
                        </p>
                        <p>
                            Los empleados que reciben servicios de cortes&#237;a de Xfinity no son
                            elegibles para participar en el Programa con respecto a la direcci&#243;n
                            en la que reciben los servicios de cortes&#237;a. Los empleados,
                            funcionarios, directores, agentes y representantes de Xfinity y sus
                            afiliados que sean elegibles y se inscriban en el Programa podr&#237;an no
                            ser elegible para todas las recompensas. El Programa est&#225; limitado a
                            una (1) cuenta por cliente/hogar y no puede transferirse. Si un Miembro
                            tiene m&#250;ltiples Cuentas, la Cuenta con la Permanencia m&#225;s larga
                            del Miembro se considerar&#225; la Cuenta del programa, pero las
                            Recompensas se pueden aplicar a las otras cuentas. Los clientes que solo
                            reciben servicios de Xfinity a trav&#233;s de servicios al por mayor o
                            cuentas, acuerdos o arreglos similares a trav&#233;s de los cuales los
                            servicios son pagados por un tercero, no son elegibles para participar en
                            el Programa a menos que dichos clientes hayan establecido Cuentas
                            facturadas individualmente y est&#233;n adquiriendo los servicios
                            requeridos bajo esa Cuenta. Los miembros que canjeen ciertas Recompensas
                            podr&#237;an no ser elegibles para canjear Recompensas identificadas
                            adicionales dentro de per&#237;odos espec&#237;ficos.
                        </p>
                        <p>
                            2. <u>Inscripci&#243;n</u>. Para inscribirse en el Programa, debe (1)
                            visitar es.Xfinity.com/Rewards a trav&#233;s de su navegador web o a
                            trav&#233;s de la Xfinity App, (2) ingresar usando sus credenciales de
                            Xfinity y (3) hacer clic para inscribirse en el Programa. Al hacer clic
                            para inscribirse, acepta los T&#233;rminos del programa. Los clientes de
                            Xfinity elegibles que se inscriban en el Programa se denominan en el
                            presente "Miembros" o un "Miembro". El Programa es para su uso personal y
                            no se puede usar para ning&#250;n negocio u otro prop&#243;sito
                            comercial.
                        </p>
                        <p>
                            3. Niveles del Programa. Hay cuatro (4) niveles (&#8220;Nivel(es)&#8221;)
                            de suscripci&#243;n en el Programa: Plata, Oro, Platino y Diamante. Cada
                            Nivel y Recompensas disponibles para cada Nivel corresponder&#225;n a la
                            cantidad de tiempo que la Cuenta de un Miembro permanezca continuamente
                            activa y al d&#237;a, seg&#250;n lo determine Xfinity a su exclusivo
                            criterio (esa es su "Permanencia").
                        </p>
                        <p>
                            (a) A medida que aumente su Permanencia y avance de Nivel, ser&#225;
                            elegible para obtener m&#225;s Recompensas.
                        </p>
                        <p>
                            (b) Su Permanencia inicial se determinar&#225; seg&#250;n la fecha en la
                            que estableci&#243; su Cuenta actual con Xfinity.
                        </p>
                        <p>
                            (c) Si todos sus servicios de Xfinity elegibles se cancelan por alg&#250;n
                            motivo, no ser&#225; elegible para recibir Recompensas. Si restablece un
                            servicio elegible en una Cuenta con las mismas credenciales de Xfinity que
                            us&#243; para inscribirse en el Programa dentro de los 90 d&#237;as
                            posteriores a la finalizaci&#243;n de sus servicios, su estado de
                            Permanencia en el momento de finalizar sus servicios se restablecer&#225;.
                            Si no restablece un servicio elegible dentro del per&#237;odo de 90
                            d&#237;as, ser&#225; eliminado autom&#225;ticamente del Programa. No
                            podr&#225; canjear Recompensas una vez que deje de ser Miembro.
                        </p>
                        <p>
                            Cada Nivel desbloquear&#225; ciertos beneficios para que los Miembros los
                            disfruten en el Programa. Los l&#237;mites de Nivel est&#225;n determinados
                            &#250;nicamente por Xfinity y est&#225;n sujetos a cambios. Para ver los
                            l&#237;mites actuales de Nivel, haga clic en <a href="#" className="underline"> es.Xfinity.com/Rewards </a>
                        </p>
                        <p>
                            4. Recompensas: Las recompensas pueden incluir acceso a eventos especiales,
                            ofertas por tiempo limitado, oportunidades de sorteos, descuentos en
                            productos y otras Recompensas que Xfinity pueda establecer y ofrecer en
                            virtud del Programa de vez en cuando. Las Recompensas se denominan en estos
                            T&#233;rminos "Recompensas" o una "Recompensa".
                        </p>
                        <p>
                            Las Recompensas disponibles para un Miembro en cualquier momento dado
                            depender&#225;n del Nivel de ese miembro y solo estar&#225;n disponibles
                            mientras duren las existencias. Xfinity puede cambiar las Recompensas en
                            cualquier momento. Las Recompensas pueden tener t&#233;rminos adicionales
                            que se aplican, incluidos, entre otros, t&#233;rminos adicionales de
                            cualquier empresa de terceros que ofrezca la Recompensa ("Socio(s) de
                            Recompensas"), una fecha de vencimiento u otras condiciones. Se requiere
                            que los Miembros compartan informaci&#243;n con Xfinity o cualquier Socio
                            de Recompensas para recibir esa Recompensa. Xfinity no es responsable de
                            los actos u omisiones de ning&#250;n Socio de Recompensas, incluido el
                            cumplimiento de las Recompensas. La informaci&#243;n proporcionada al Socio
                            de Recompensas para reclamar o usar una Recompensa est&#225; sujeta a la
                            pol&#237;tica de privacidad del Socio de Recompensas. Solo el propietario
                            principal de la Cuenta del Miembro puede reclamar y usar las Recompensas.
                            Los miembros pueden verificar los detalles de las Recompensas en su Cuenta
                            individual, incluida la disponibilidad, las instrucciones de reclamo, la
                            informaci&#243;n sobre las fechas de vencimiento que pueden aplicarse y el
                            estado de la orden visitando es.Xfinity.com/Rewards a trav&#233;s de su
                            navegador web o de la Xfinity App ingresando con sus credenciales de
                            Xfinity.
                        </p>
                        <p>
                            Las Recompensas no tienen valor en efectivo (a menos que lo exija la ley) y
                            no pueden venderse, revenderse, devolverse, intercambiarse ni sustituirse,
                            excepto por Xfinity, quien puede, a su exclusivo criterio, sustituir las
                            Recompensas en cualquier momento. Xfinity no es responsable de las
                            Recompensas perdidas, robadas o da&#241;adas. Si un Miembro pone a la venta
                            una Recompensa o vende una Recompensa, la Recompensa puede perderse y
                            anularse. Xfinity no es responsable de los impuestos incurridos por los
                            Miembros en relaci&#243;n con las Recompensas. Es posible que se le
                            solicite a Xfinity que informe de las cantidades imponibles relacionadas
                            con las Recompensas a las autoridades fiscales correspondientes. Algunas
                            Recompensas podr&#237;an estar solo disponibles a residentes de un
                            &#225;rea o estado particular. Las Recompensas est&#225;n sujetas a cambio,
                            alteraci&#243;n, sustituci&#243;n o cancelaci&#243;n por parte de Xfinity a
                            su entera discreci&#243;n.
                        </p>
                        <p>
                            A menos que se indique lo contrario como parte de una Recompensa, no se
                            incluir&#225; el transporte hacia/desde un evento de Recompensa. Las
                            fechas, los horarios y los asientos de los eventos de Recompensas se
                            determinan a discreci&#243;n exclusiva de Xfinity y est&#225;n sujetos a
                            cambios. Si un Miembro o sus invitados no pueden asistir a un evento en la
                            fecha especificada, perder&#225;n la Recompensa. Xfinity no es responsable
                            de reemplazar boletos perdidos, da&#241;ados o robados para eventos de
                            Recompensas. Si alg&#250;n evento de Recompensa, o cualquier evento
                            relacionado, no se lleva a cabo seg&#250;n lo programado o se cancela por
                            cualquier motivo, incluso debido a casos fortuitos (como, entre otros,
                            incendios, explosiones, terremotos e inundaciones), actos de terrorismo,
                            epidemia, pandemia, disturbios c&#237;vicos, paro laboral o cualquier otro
                            desastre natural ("Evento de Fuerza Mayor"), no se ofrecer&#225; la
                            Recompensa y no se proporcionar&#225; ninguna sustituci&#243;n o
                            compensaci&#243;n.
                        </p>
                        <p>
                            Las personas deben tener veinti&#250;n (21) a&#241;os o m&#225;s para beber
                            alcohol en cualquier evento de Recompensa. Los eventos de Recompensas y los
                            boletos estar&#225;n disponibles en cantidades limitadas por orden de
                            llegada hasta agotar existencias. Cada evento de Recompensas y boleto de
                            Recompensas tendr&#225; sus propios t&#233;rminos que se aplicar&#225;n.
                            Los Miembros y su(s) invitado(s) aceptan cumplir con todos los
                            t&#233;rminos, reglas y reglamentaciones correspondientes al evento y los
                            boletos, incluidas, entre otras, las reglamentaciones del lugar del evento.
                            Xfinity y el lugar correspondiente para el evento de Recompensa se reservan
                            el derecho de negar la entrada o retirar a los Miembros y sus invitados del
                            lugar si los Miembros o sus invitados se comportan de manera disruptiva,
                            sin compensaci&#243;n ni responsabilidad adicional de cualquier tipo a los
                            Miembros y sus invitados (seg&#250;n corresponda). La participaci&#243;n en
                            el Programa, incluido el uso de un boleto y/o la asistencia a un evento de
                            Recompensa y eventos relacionados, constituye el permiso de un Miembro para
                            que Xfinity y sus designados usen los nombres, grabaciones, comentarios e/o
                            im&#225;genes y fotos de los Miembros y sus invitados en anuncios y redes
                            sociales con fines publicitarios y comerciales sin compensaci&#243;n
                            adicional para los Miembros y sus invitados, a menos que lo proh&#237;ba la
                            ley.
                        </p>
                        <p>
                            Las Recompensas pueden pertenecer a varias categor&#237;as, incluidas,
                            entre otras, las siguientes:
                        </p>
                        <p>
                            (a) Recompensas de Sorpresa y Agrado: Las Recompensas de Sorpresa y Agrado
                            est&#225;n limitadas a Miembros a partir de una fecha determinada y se
                            proporcionan sin previo aviso. Las Recompensas de Sorpresa y Agrado pueden
                            incluir, entre otras, oportunidades de boletos para ciertos eventos
                            deportivos, musicales, de entretenimiento y espect&#225;culos ubicados en
                            todo Estados Unidos. Los boletos tambi&#233;n pueden incluir acceso
                            especial a experiencias. Cada Recompensa de Sorpresa y Agrado tendr&#225;
                            sus propios t&#233;rminos que se aplicar&#225;n.
                        </p>
                        <p>
                            (b) <strong>Ofertas y descuentos:</strong> las Recompensas pueden incluir
                            acceso a ofertas y descuentos por tiempo limitado para productos y
                            servicios de Xfinity, as&#237; como ofertas y/o descuentos de nuestros
                            socios externos. Cada oferta o descuento tendr&#225; sus propios
                            t&#233;rminos que se aplicar&#225;n.
                        </p>
                        <p>
                            (c) <strong>Sorteos:</strong> las Recompensas pueden incluir la oportunidad
                            de participar en sorteos aleatorios ("Sorteo"). Cada Sorteo tendr&#225; sus
                            propias reglas oficiales y tendr&#225; su propia fecha de inicio, fecha de
                            finalizaci&#243;n, premio(s) y condiciones de participaci&#243;n.
                        </p>
                        <p>
                            5. <strong>Comunicaciones con Xfinity y Privacidad:</strong> Al convertirse
                            en Miembro del Programa, los Miembros aceptan recibir publicidad,
                            materiales de mercadeo y otras comunicaciones de Xfinity relacionadas con
                            el Programa y se suscribir&#225;n autom&#225;ticamente para recibir emails
                            del Programa. Los miembros aceptan que Xfinity puede enviarles mensajes
                            transaccionales e informaci&#243;n sobre el Programa por email, correo
                            postal, mensaje de texto o notificaciones autom&#225;ticas a su dispositivo
                            m&#243;vil. Si los Miembros no desean recibir comunicaciones no
                            transaccionales, los Miembros pueden solicitar que se interrumpan estas
                            comunicaciones visitando el Centro de Preferencia en Comunicaciones de
                            Xfinity en es.Xfinity.com/Preferences. Cambios, terminaci&#243;n o
                            eliminaci&#243;n del programa: Xfinity puede, a su exclusivo criterio,
                            alterar, limitar y/o modificar estos T&#233;rminos, su sitio web, las
                            reglas del Programa, las reglamentaciones, las Recompensas, los beneficios,
                            la elegibilidad para convertirse en Miembro y/ o cualquier otra
                            caracter&#237;stica del Programa (incluida la asignaci&#243;n de cualquiera
                            de sus obligaciones con los clientes en virtud del Programa en cualquier
                            momento a otra persona o entidad, sin recurso), o puede eliminar el
                            Programa en cualquier momento a su exclusivo criterio, con o sin
                            notificaci&#243;n. Es responsabilidad de los Miembros verificar y revisar
                            estos T&#233;rminos de vez en cuando para mantenerse informados de
                            cualquier cambio. Al unirse al Programa, los Miembros aceptan estar sujetos
                            a todos los T&#233;rminos modificados.
                        </p>
                        <p>
                            Si un Miembro cancela su Cuenta, un Miembro cancela todos sus servicios
                            elegibles (sujeto a las disposiciones de la Secci&#243;n 3), la Cuenta de
                            un Miembro ya no se considera que est&#233; al corriente, o la
                            participaci&#243;n en el Programa de un Miembro finaliza de otro modo, ese
                            Miembro perder&#225; cualquier Recompensa o beneficio disponible no usado.
                            Xfinity puede monitorear la participaci&#243;n y la actividad del Programa
                            de Miembros para el cumplimiento de estos T&#233;rminos. Xfinity puede
                            suspender o cancelar la participaci&#243;n en el Programa de un Miembro en
                            cualquier momento. Los Miembros pueden perder beneficios o la capacidad de
                            reclamar nuevos beneficios en el Programa si el estado de su Cuenta cambia,
                            si no realizan los pagos en su Cuenta, si Xfinity sospecha actividad
                            fraudulenta, el Miembro vende las Recompensas recibidas del Programa o
                            Xfinity realiza cambios en el Programa.
                        </p>
                        <p>
                            Xfinity se reserva el derecho de excluir a personas del Programa a su
                            exclusivo pero razonable criterio. En particular, cualquier abuso,
                            manipulaci&#243;n o "juego" del Programa o estos T&#233;rminos (seg&#250;n
                            lo determine Xfinity), incumplimiento de los t&#233;rminos del Programa,
                            cualquier tergiversaci&#243;n o cualquier conducta perjudicial para los
                            intereses de Xfinity que no est&#233; protegida por la ley puede someter a
                            los Miembros a la revocaci&#243;n de la Suscripci&#243;n o la
                            reducci&#243;n del estado de Nivel obtenido a trav&#233;s de las
                            actividades descritas en esta secci&#243;n y puede afectar la elegibilidad
                            para una mayor participaci&#243;n en el Programa. La Suscripci&#243;n no es
                            transferible y los reclamos y canjes de la Suscripci&#243;n deben ser
                            realizados por el Miembro. Excepto por lo dispuesto en la Secci&#243;n 3,
                            si se revoca o cancela una Suscripci&#243;n, cualquier estado de Nivel en
                            la Cuenta del Programa aplicable caducar&#225; autom&#225;ticamente.
                        </p>
                        <p>
                            7. <strong>Limitaci&#243;n de responsabilidad; Lanzamientos:</strong>
                        </p>
                        <p>
                            TODAS LAS RECOMPENSAS SE PROPORCIONAN "TAL CUAL" SIN GARANT&#205;AS.
                            COMCAST RENUNCIA A TODAS LAS GARANT&#205;AS, YA SEA EXPL&#205;CITAS O
                            IMPL&#205;CITAS, INCLUIDAS LAS GARANT&#205;AS DE COMERCIABILIDAD E
                            IDONEIDAD PARA UN PROP&#211;SITO EN PARTICULAR.
                        </p>
                        <p>
                            Ni Xfinity ni ning&#250;n lugar de venta de boletos o Socio de Recompensas
                            tendr&#225;n responsabilidad u obligaci&#243;n alguna por cualquier
                            p&#233;rdida de propiedad, da&#241;os, lesiones personales o muerte, en
                            relaci&#243;n con cualquier Recompensa proporcionada como parte del
                            Programa y/o la participaci&#243;n de cualquier persona en cualquier evento
                            de Recompensa o eventos relacionados. Cuando un Miembro reclama y/o usa una
                            Recompensa o participa en el Programa, el Miembro libera a Xfinity, sus
                            empresas matrices, afiliadas y subsidiarias, todos los Socios de
                            Recompensas y sus funcionarios, empleados y agentes de todos los reclamos,
                            acciones, causas de acciones y da&#241;os, incluidos, entre otros, reclamos
                            de lesiones personales, incluida la muerte, da&#241;os a la propiedad e
                            infracci&#243;n de los derechos de publicidad, que surjan del uso de
                            Recompensas o en relaci&#243;n con la participaci&#243;n en eventos de
                            Recompensas o el Programa. Xfinity y los miembros aceptan limitar los
                            reclamos entre ellos por da&#241;os u otra compensaci&#243;n monetaria a
                            da&#241;os directos &#250;nicamente. Esta limitaci&#243;n y renuncia se
                            aplicar&#225; independientemente de la teor&#237;a de la responsabilidad.
                            Esta limitaci&#243;n de responsabilidad tambi&#233;n se aplica si un
                            Miembro presenta un reclamo contra cualquier Socio de Recompensas, en la
                            medida en que Xfinity deba indemnizar a dicha compa&#241;&#237;a contra
                            cualquier reclamo. Los Miembros aceptan que Xfinity no es responsable de
                            los problemas causados por cualquier Socio de Recompensas o un lugar de
                            evento, Miembro, otros, o por cualquier Evento de fuerza mayor.
                        </p>
                        <p>
                            8. Estos T&#233;rminos incorporan y complementan al Acuerdo para Servicios
                            Residenciales de Comcast, T&#233;rminos de Xfinity Internet y Xfinity Voice
                            adicionales, y Aviso de Privacidad para clientes de Xfinity Comcast y, en
                            caso de conflicto con estos T&#233;rminos, el Acuerdo para Servicios
                            Residenciales de Comcast, T&#233;rminos de Xfinity Internet y Xfinity Voice
                            adicionales, y Aviso de Privacidad para clientes de Xfinity Comcast
                            prevalecer&#225;n.
                        </p>
                        <p>
                            <strong>
                                Informaci&#243;n adicional sobre el Programa: Para resolver cualquier
                                duda sobre el Programa, visite <a href="#" className="underline">es.Xfinity.com/Rewards</a>
                            </strong>
                            
                        </p>

                    </div>}
                </div>
            </div>
        </ReactModal >
    )
}

export default Terms