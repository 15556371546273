import React from "react";
import { useTranslation } from "react-i18next";

const Longer = ({props}) => {
    const {t} = useTranslation()
    return(
    <div id="Longer" className="slide">

            <div className="grid place-items-center w-full">
                <div className="">
                    <h1 className="font-x-bold text-center text-[3.125rem]">{t('Longer-h1')}</h1>
                    <h2 className="text-x-purple font-x-bold text-center text-[3.125rem]">{t('Longer-h2')}</h2>
                </div>
                <div className="grid grid-cols-4 gap-8 landscape:w-4/5 portrait:w-11/12">
                    <div className="grid grid-cols-1">
                        <img src="/imgs/xr_heart_gray.png" />
                        <p className="text-center font-x-bold text-[1.375rem] mb-8">{t('Longer-s1')}</p>
                        <p className="text-center text-[1.375rem]">{t('Longer-p1')}</p>
                    </div>
                    <div className="grid grid-cols-1">
                        <img src="/imgs/xr_heart_gold.png" />
                        <p className="text-center font-x-bold text-[1.375rem] mb-8">{t('Longer-s2')}</p>
                        <p className="text-center text-[1.375rem]">{t('Longer-p2')}</p>
                    </div>
                    <div className="grid grid-cols-1">
                        <img src="/imgs/xr_heart_plat.png" />
                        <p className="text-center font-x-bold text-[1.375rem] mb-8">{t('Longer-s3')}</p>
                        <p className="text-center text-[1.375rem]">{t('Longer-p3')}</p>
                    </div>
                    <div className="grid grid-cols-1">
                        <img src="/imgs/xr_heart_diamond.png" />
                        <p className="text-center font-x-bold text-[1.375rem] mb-8">{t('Longer-s4')}</p>
                        <p className="text-center text-[1.375rem]">{t('Longer-p4')}</p>
                    </div>
                </div>
            </div>

    </div>
    )
}

export default Longer