import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import CloseButton from "./Close";

const Jimmy = ({ hideModal, customStyles }) => {
    const [state, setState] = useState(false)
    const { t, i18n } = useTranslation()
    return(
        <ReactModal isOpen style={customStyles}>
            <div className="relative">
                <div className="flex flex-col items-center px-8 gap-4">
                    <h1 className="text-[2rem] mt-24 text-center max-w-[28ch] font-x-bold">{t('Modal-Jimmy-h1')}</h1>
                    <p className="text-xl mb-4" >{t('Modal-Jimmy-p1')}</p>
                    <div className="flex">
                        <p className="text-sm text-x-gray2 underline" onClick={_ => setState(!state)} >{t('Modal-Jimmy-btn')}</p><img className={`${state ? `rotate-180` : `rotate-0`}`} src="/imgs/carret_down.svg" />
                    </div>
                    {i18n.resolvedLanguage === 'en' && state && <div className="flex flex-col gap-4 text-x-gray2">
                        <p>
                            NO PURCHASE NECESSARY; Void where prohibited 18+. US. Sweepstakes ends
                            6/30/23. Employees of Comcast, GMR Marketing and NBCUniversal are not
                            eligible. See Official Rules at <a href="#" className="underline">www.therulespage.com/XfinityNBCUTheTonightShow</a> for additional eligibility restrictions and complete details.
                        </p>
                    </div>}
                    {i18n.resolvedLanguage === 'es' && state && <div className="flex flex-col gap-4 text-x-gray2">
                        <p>
                            SIN OBLIGACI&#211;N DE COMPRA; nulo donde est&#233; prohibido 18+. US. El
                            sorteo finaliza en 6/30/23. Los empleados de Comcast, GMR Marketing y
                            NBCUniversal no son elegibles. Consulte las Reglas oficiales en <a href="#" className="underline">www.therulespage.com/XfinityNBCUTheTonightShow</a> para conocer las restricciones de elegibilidad adicionales y los detalles
                            completos.
                        </p>
                    </div>}
                </div>

                <div className="absolute right-4 top-4 cursor-pointer" onClick={hideModal}><CloseButton /></div>
            </div>
            <div className={`${state ? '': 'absolute bottom-0'} mt-8`}>
                    <img src="/imgs/modal_jimmy.png" />
            </div>
        </ReactModal>

    )
}

export default Jimmy