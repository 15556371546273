import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: true,
    resources: {
      en: {
        translation: {
          "btn-join":"Join Now",
          "switch-en":"ENGLISH",
          "switch-es":"ESPAÑOL",
          "Attract": "Attract",
          "Attract-scroll": "Scroll down to learn more",
          "HowItWorks-h1": "How It Works",
          "HowItWorks-s1": "Join for free",
          "HowItWorks-s2": "Unlock rewards",
          "HowItWorks-s3": "See what's new",
          "HowItWorks-p1": "Just log into your Xfinity account to get started",
          "HowItWorks-p2": "Your rewards start from day one and keep on coming",
          "HowItWorks-p3": "New rewards are always popping up like movie nights on us, discounts and giveaways",
          "Intro": "Intro",
          "Intro-h1" : "Xfinity Rewards is our way of saying thanks for being a customer",
          "Intro-p1" : "Special perks. Unique experiences. Free and easy to join.",
          "Longer-h1": "The longer you're with us,",
          "Longer-h2": "the better it gets",
          "Longer-s1": "Silver",
          "Longer-p1": "0-2 years",
          "Longer-s2": "Gold",
          "Longer-p2": "2-7 years",
          "Longer-s3": "Platinum ",
          "Longer-p3": "7-14 years",
          "Longer-s4": "Diamond",
          "Longer-p4": "14+ years",
          "Outro-l1": "By joining Xfinity Rewards",
          "Outro-l2": "you are accepting our terms and conditions.",
          "Outro-h1": "Join. Explore. Enjoy.",
          "Outro-p1": "Scan the QR Code to download the Xfinity app, then sign up for rewards.",
          "Rewards-h1": "Rewards are waiting for you",
          "Rewards-h2": "Here are just a few examples of Xfinity Rewards.",
          "Rewards-t1": "Enter for a chance to win once in a lifetime experiences ",
          "Rewards-s1": "Xfinity Rewards membership not required to enter sweepstakes",
          "Rewards-t2": "Special Xfinity Mobile offers",
          "Rewards-t3": "Universal Orlando Resort discounts",
          "Rewards-t4": "$1 movie weekends and flicks on us",
          "Rewards-t5": "Fun perks for sports fans",
          "Rewards-t6": "Check back for the latest perks and experiences!",
          "Modal-Terms-h1": "General Terms and Conditions",
          "Modal-Movies-h1" : "Enjoy your next movie night on Xfinity X1, Flex or the Stream app",
          "Modal-Mobile-h1" : "Enjoy rewards like discounts on eligible mobile accessories and more",
          "Modal-Sports-h1" : "Check out the Xfinity app and explore your sports rewards today",
          "Modal-Learn-h1" : "Download the Xfinity app to stay in the know",
          "Modal-Orlando-h1" : "As an Xfinity Rewards member, enjoy a Universal Orlando Resort vacation package at discounted prices",
          "Modal-Orlando-btn" : "Details & Restrictions",
          "Modal-Jimmy-h1" : "You can enter for a chance to win tickets to a live taping of The Tonight Show with Jimmy Fallon",
          "Modal-Jimmy-p1" : "You don't even need to be an Xfinity Rewards member",
          "Modal-Jimmy-btn" : "Details & Restrictions",
        }
      },
      es: {
        translation: {
          "btn-join":"Únete ahora",
          "switch-en":"INGLES",
          "switch-es":"ESPAÑOL",
          "Attract": "Atraer",
          "Attract-scroll": "Ve hacia abajo para más información",
          "HowItWorks-h1": "Cómo funciona",
          "HowItWorks-s1": "Suscríbete gratis",
          "HowItWorks-s2": "Descubre tus recompensas",
          "HowItWorks-s3": "Ve lo nuevo",
          "HowItWorks-p1": "Para empezar, simplemente ingresa en tu cuenta de Xfinity",
          "HowItWorks-p2": "Tus recompensas empiezan el primer día y seguirán llegando",
          "HowItWorks-p3": "Continuamente se agregan nuevas recompensas como noches de cine por cuenta nuestra, descuentos y regalos",
          "Intro": "Introducción",
          "Intro-h1" : "Xfinity Rewards es nuestra manera de darte las gracias por ser nuestro cliente",
          "Intro-p1" : "Beneficios especiales. Experiencias únicas. Suscribirte es fácil y gratis.",
          "Longer-h1": "Cuanto más tiempo estés con nosotros,",
          "Longer-h2": "mejor se pondrán las recompensas.",
          "Longer-s1": "Plata",
          "Longer-p1": "0-2 años",
          "Longer-s2": "Oro",
          "Longer-p2": "2-7 años",
          "Longer-s3": "Platino",
          "Longer-p3": "7-14 años",
          "Longer-s4": "Diamante",
          "Longer-p4": "Más de 14 años",
          "Outro-l1": "Al suscribirte a Xfinity Rewards, aceptas",
          "Outro-l2": "nuestros términos y condiciones.",
          "Outro-h1": "Únete. Explora. Disfruta.",
          "Outro-p1": "Escanea el código QR para descargar la Xfinity App y luego regístrate para obtener recompensas.",
          "Rewards-h1": "Tus recompensas te esperan",
          "Rewards-h2": "Estos son solo algunos ejemplos de Xfinity Rewards",
          "Rewards-t1": "Ingresa para tener la oportunidad de ganar experiencias únicas ",
          "Rewards-s1": "No se requiere la suscripción a Xfinity Rewards para ingresar en los sorteos",
          "Rewards-t2": "Ofertas especiales de Xfinity Mobile",
          "Rewards-t3": "Descuentos para Universal Orlando Resort ",
          "Rewards-t4": "Fines de<br />semana de cine a $1 y películas por cuenta nuestra",
          "Rewards-t5": "Regalos divertidos para fans deportivos",
          "Rewards-t6": "Vuelve a visitarnos para ver los últimos regalos y experiencias!",
          "Modal-Terms-h1": "Términos y condiciones generales",
          "Modal-Movies-h1" : "Disfruta tu próxima noche de cine en Xfinity X1, Flex o la Stream app",
          "Modal-Mobile-h1" : "Disfruta recompensas como descuentos en accesorios para dispositivos móviles elegibles y más ",
          "Modal-Sports-h1" : "Echa un vistazo a la aplicación Xfinity y explora tus recompensas deportivas hoy",
          "Modal-Learn-h1" : "Descarga la aplicación Xfinity para mantenerte informado",
          "Modal-Orlando-h1" : "Como miembro de Xfinity Rewards, disfruta de un paquete vacacional en Universal Orlando Resort a precios con descuento",
          "Modal-Orlando-btn" : "Detalles y restricciones",
          "Modal-Jimmy-h1" : "Puedes participar para tener la oportunidad de ganar boletos para una grabación en vivo de The Tonight Show con Jimmy Fallon",
          "Modal-Jimmy-p1" : "Ni siquiera necesitas ser miembro de Xfinity Rewards",
          "Modal-Jimmy-btn" : "Detalles y restricciones",

          
        }
      }
    },
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });
