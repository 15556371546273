import React from "react";

const CloseButton = _ =>{
    return(
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.622155" y1="15.6725" x2="13.6222" y2="0.672536" stroke="#838383"/>
            <line x1="0.365528" y1="0.658841" x2="14.3655" y2="15.6588" stroke="#838383"/>
        </svg>
    )
}

export default CloseButton
