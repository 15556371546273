import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18next'
import queryString from 'query-string';

window._modalClosers = []

// Test URL https://ipadrewards.click/?language=en&sessionId=123456&store=walnut%20st.%20(2327)
const parsed = queryString.parse(window.location.search);
if( window.location.host === "ipadrewards.click"
 && Object.keys(parsed).length != 0
 && parsed.language != undefined
 && parsed.store != undefined
 && parsed.sessionId != undefined
)
{
  window.dataLayer.push({'event':`qrScan`, 'language': `${parsed.language}`, 'store': `${parsed.store}`, 'sessionId': `${parsed.sessionId}`, 'app_version': `${window.__version}`})
  if(parsed.language.toLocaleLowerCase() === 'en') {
    window.location.href = "https://www.xfinity.com/apps"
  } else {
    window.location.href = "https://es.xfinity.com/apps"
  }
} else if(window.location.host == "main.d2dus3zktbk8ur.amplifyapp.com" 
|| window.location.host == "dev.d2dus3zktbk8ur.amplifyapp.com"
|| window.location.host == "localhost:3000"
|| window.location.host == "bs-local.com:3000"
) {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
} else { window.location.href = "https://www.xfinity.com/apps" }

// add service worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', function() {
    navigator.serviceWorker.register('/sw.js').then(function(registration) {
      // Registration was successful
      console.log('ServiceWorker registration successful with scope: ', registration.scope);
    }, function(err) {
      // registration failed :(
      console.log('ServiceWorker registration failed: ', err);
    });
  });
}


window.__version = "1.1.12";
const checkForUpdate = () => {
  console.log('checking for new version')
  // get the file version.txt and read it's contents
  const store = localStorage.getItem('store') || 'unknown';
  fetch(`/version.txt?v=${window.__version}&store=${encodeURIComponent(store)}`)
    .then(response => response.text())
    .then((data) => {
      // if the version has changed, reload the page
      if (window.__version !== data) {
        console.log('new version found')
        //__sendMessageHome(`iPad found a new version. new version: ${data} prev version: ${window.__version} store: ${store}`)
        const url = new URL(window.location.href);
        url.searchParams.set('reloadTime', Date.now().toString());
        url.searchParams.set('version', window.__version);
        url.searchParams.set('store', encodeURIComponent(store));
        window.location.href = url.toString();
      }
    }).catch((error) => {
      //__sendMessageHome(`iPad did not get update. version: ${window.__version} store: ${store} error: ${error.message}`)
    });
//  }, 1000 * 30); // test in 30 sec
}
setInterval(() => checkForUpdate, 1000 * 86400); // Check in once a day
checkForUpdate(); // Check now

// function __sendMessageHome (message) {
//   const myHeaders = new Headers();
//   myHeaders.append("Content-Type", "application/json");

//   const raw = JSON.stringify({
//     "text": `${message}`
//   });

//   const requestOptions = {
//     method: "POST",
//     headers: myHeaders,
//     body: raw,
//     redirect: "follow",
//     mode: "no-cors", // Set to 'no-cors' for no CORS
//     cache: "no-cache", // 
//   };

//   fetch("https://interpublic.webhook.office.com/webhookb2/02433416-3ed4-43bd-9d02-c7da8e4c7f97@d026e4c1-5892-497a-b9da-ee493c9f0364/IncomingWebhook/65ef6e7a8aa045929023c21885c87424/06b5ec77-01e6-46f8-9645-6703fd50a99e", requestOptions)
//     .then((response) => response.text())
//     .then((result) => console.log(result))
//     .catch((error) => console.error(error));
//   }


