import React from "react";
import { useTranslation } from "react-i18next";

const Intro = ({props}) => {
    const {t} = useTranslation()
    return(
    <div id="Intro" className="slide bg-x-purple text-white relative mb-8">
        <div className="grid auto-cols-auto gap-8 place-items-center landscape:w-11/12 portrait:w-11/12">
            <div className="row-start-1 grid grid-cols-1 gap-4">
                <h1 className="font-x-bold">{t('Intro-h1')}</h1>
                <p>{t('Intro-p1')}</p>
            </div>
            <div className="row-start-1 w-[420px] h-[433px] bg-cover" style={{backgroundImage: "url('/imgs/XR_4HeartHero.png')"}}></div>
        </div>
    </div>
    )
}

export default Intro