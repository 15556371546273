import { QRCodeSVG } from "qrcode.react";
import React, {useEffect} from "react";
import { useTranslation, Trans } from "react-i18next";
import { useModal } from "react-modal-hook";
import StoreLocator from "../Modals/StoreLocator";
import Terms from "../Modals/Terms";
import {getStore} from "../Utills"

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: 'min(98vh, 95vw)',
      height: 'min(95vh, 95vw)',
      borderRadius: '1rem',
      padding: 'none'
    },
  };
const Outro = ({ props }) => {
    const { t, i18n } = useTranslation()
    const [showModal001, hideModal001] = useModal(() => <Terms hideModal={hideModal001} customStyles={customStyles} />);
    const [showModal000, hideModal000] = useModal(() => <StoreLocator hideModal={hideModal000} customStyles={customStyles} />);

    useEffect(() => {
        showModal000()
      return () => { }
    }, [showModal000])

    useEffect(() => { 
      if(!window._modalClosers.find(x => x === hideModal001)) { window._modalClosers.push(hideModal001) }
      return () => {
        window._modalClosers = window._modalClosers.reduce((a,x) => { if(x !== hideModal001) { a.push(x) } return a }, [])
      }
    }, [hideModal001])
    
    const sendModalEvent = modal => {
        window.dataLayer.push({'event':`modalView`, 'modal': `${modal}`, 'language': `${i18n.resolvedLanguage}`, 'store': `${getStore()}`, 'sessionId': `${window._activeSession}`, 'app_version': `${window.__version}`})
    }

    return (
        <div id="Outro" className="slide">
            <div className="absolute bottom-[56px] left-16 right-16">
                <div className="bg-x-purple rounded-t-3xl relative grid place-items-center text-white">
                    <div className="relative -top-1/2">
                        <QRCodeSVG className="rounded-xl" value={`https://ipadrewards.click/?language=${i18n.resolvedLanguage}&sessionId=${window._activeSession}&store=${encodeURIComponent(getStore())}`} includeMargin size={300} />
                    </div>
                    <div className="absolute left-8 right-8 bottom-8 flex justify-between items-center">
                        <div className="">
                            <h1 className="text-4xl">{t('Outro-h1')}</h1>
                            <p className={`text-xl ${i18n.resolvedLanguage === 'en' ? "max-w-[37ch]" : "max-w-[39ch]"}`}>{t('Outro-p1')}</p>
                        </div>
                        <div>
                            <img src="/imgs/footer-logo.png" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="absolute bottom-0 left-0 right-0 h-[56px]">
                <div className="bg-x-gray p-4">
                    <p className="text-center">{t('Outro-l1')}, <span onClick={_ => {sendModalEvent(`Terms`); showModal001()}} className="underline cursor-pointer">{t('Outro-l2')}</span></p>
                    <p className="text-center text-xs">version {window.__version}</p>
                </div>
            </div>
        </div>
    )
}

export default Outro