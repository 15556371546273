import React from "react";
import { useTranslation } from "react-i18next";

const AttractLoop = ({props}) => {
    const {t, i18n} = useTranslation()
    return(
    <div id="Attract" className="slide relative overflow-hidden bg-x-purple pb-6">
        <div className="w-full h-screen relative bg-white overflow-hidden">
            {i18n.resolvedLanguage === 'en' &&
                <dotlottie-player src="https://lottie.host/24b9c06b-44c2-41dd-a0d7-829d29c2c97c/1Arw3CUmBb.json" background="transparent" speed="1" style={{width: `1475px`, height: `950px`, position:"absolute",top:"-80px",left: "-144px"}} loop autoplay></dotlottie-player>
            }
            {i18n.resolvedLanguage === 'es' &&
                <dotlottie-player src="https://lottie.host/abf205af-0195-4d1e-baa9-01d24c9e9c3e/VI3ltkSOLc.json" background="transparent" speed="1"  style={{width: `1475px`, height: `950px`, position:"absolute",top:"-80px",left: "-144px"}} loop autoplay></dotlottie-player>
            }
        </div>
        <div className="absolute bottom-6 bg-x-purple text-white landscape:w-7/12 portrait:w-2/4 rounded-t-2xl text-center py-2">
            <div className="flex justify-center items-center gap-4">
                <p>{t('Attract-scroll')}</p><img className="w-4 h-4" src="/imgs/chevron-down.png" />
            </div>
        </div>
    </div>
    )
}

export default AttractLoop