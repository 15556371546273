import React from "react";
import { useTranslation } from "react-i18next";

const HowItWorks = ({ props }) => {
    const { t } = useTranslation()
    return (
        <div id="HowItWorks" className="slide">
            <div className="grid auto-cols-auto gap-4 place-items-center items-baseline landscape:w-4/5 portrait:w-11/12">
                <h1 className="col-span-3 font-x-bold mb-4">{t('HowItWorks-h1')}</h1>
                    <img className="" src="/imgs/ico-join.png" />
                    <img className="" src="/imgs/ico-sparkle.png" />
                    <img className="" src="/imgs/ico-reward.png" />
                    <p className="text-center font-x-bold text-2xl">{t('HowItWorks-s1')}</p>
                    <p className="text-center font-x-bold text-2xl">{t('HowItWorks-s2')}</p>
                    <p className="text-center font-x-bold text-2xl">{t('HowItWorks-s3')}</p>
                    <p className="text-center max-w-[23ch]">{t('HowItWorks-p1')}</p>
                    <p className="text-center max-w-[23ch]">{t('HowItWorks-p2')}</p>
                    <p className="text-center max-w-[24ch]">{t('HowItWorks-p3')}</p>
            </div>
        </div>
    )
}

export default HowItWorks