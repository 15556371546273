import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import CloseButton from "./Close";

const Orlando = ({ hideModal, customStyles }) => {
    const [state, setState] = useState(false)
    const { t, i18n } = useTranslation()
    return(
        <ReactModal isOpen style={customStyles}>
            <div className="relative">
                <div className="flex flex-col items-center px-8 gap-4">
                    <h1 className="font-x-bold text-[2rem] mt-24 mb-8 text-center max-w-[35ch]">{t('Modal-Orlando-h1')}</h1>
                    <div className="flex">
                        <p className="text-x-gray2" onClick={_ => setState(!state)} >{t('Modal-Orlando-btn')}</p><img className={`${state ? `rotate-180` : `rotate-0`}`} src="/imgs/carret_down.svg" />
                    </div>
                    {i18n.resolvedLanguage === 'en' && state && <div className="flex flex-col gap-4 text-x-gray2">
                    <p>
                            <strong>Details &amp; Restrictions:</strong>
                    </p>
                    <p>
                        Restrictions apply. Offer limited to current Xfinity Rewards members with
                        account in good standing while supplies last. Offer ends
                        6/30/23.&#8239;Comcast reserves the right to modify or cancel&#8239;offer
                        at any time.&#8239;May not be assigned or transferred.&#8239;All
                        redemptions are final. To redeem offer, click &#8220;Get your
                        package&#8221; and purchase the vacation package on Universal
                        Orlando&#8217;s Xfinity Rewards Vacation Package site. Savings based on a
                        7-night stay. Restrictions apply.&#8239;
                    </p>
                    <p>
                        WIZARDING WORLD and all related trademarks, characters, names, and indicia
                        are &#169; &amp; &#8482; Warner Bros. Entertainment Inc. Publishing Rights
                        &#169; JKR. (s22)&#8239;
                    </p>
                    <p>
                        All prices, package inclusions &amp; options are subject to availability
                        and to change without notice and additional restrictions may apply. Errors
                        will be corrected where discovered, and Universal Orlando and Universal
                        Parks &amp; Resorts Vacations reserve the right to revoke any stated offer
                        and to correct any errors, inaccuracies or omissions, whether such error is
                        on a website or any print or other advertisement relating to these products
                        and services.&#8239;
                    </p>
                    <p>
                        <strong>
                            Bundle and Save up to $200 with the Xfinity Rewards Vacation Package
                        </strong>
                        &#8239;
                    </p>
                    <p>
                        Bundle and Save offer discount for Xfinity Rewards Vacation Package
                        purchased on <a href="#" className="underline" > https://www.universalorlando.com/web-packages/en/us/vacation-packages/H605/xfinity-rewards-vacation-package</a> and through the Universal Orlando Guest Contact Center. Valid for bookings
                        now-12/9/23 with travel now-12/18/23. To qualify for savings, Xfinity
                        Rewards Vacation Package must minimally include a 3-night hotel stay and a
                        3-Park Ticket OR 3-Park Annual or Seasonal Pass for each member of the
                        traveling party. Offer not valid on vacation packages with a 1-night or
                        2-night hotel stay OR 1-Park or 2-Park Tickets. Xfinity Rewards Vacation
                        Package requires a minimum length of stay to book. Savings are based on
                        length of stay. Offer valid on new bookings only and is not valid with any
                        other offers, discounts, promotions or prior purchases. Void where
                        prohibited. Additional restrictions may apply and are subject to change
                        without notice.&#8239;Bundle and Save offer discount for Xfinity Rewards
                        Vacation Package does not apply to: vacation packages offered through
                        online auction websites, membership programs, corporate discounts, group
                        rates, promotional rates offered for groups including but not limited to
                        associations, military, government, convention attendees, timeshare tour
                        offers, or promotions not offered by Universal Parks &amp; Resorts
                        Vacations to the general public. Universal Parks &amp; Resorts Vacations
                        reserves the right to change or remove applicable savings as part of the
                        Bundle and Save offer discount for Xfinity Rewards Vacation Package at any
                        time without advance notice.&#8239;&#8239;&#8239;
                    </p>
                    <p>
                        For complete terms &amp; conditions see <a href="#" className="underline" >https://www.universalorlando.com/web-packages/en/us/vacation-packages/H605/xfinity-rewards-vacation-package </a> &#8239;
                    </p>
                    <p>
                        Universal Parks &amp; Resorts Vacations is registered with the State of
                        Florida as a seller of travel. Registration number ST-24215.&#8239;&#8239;
                    </p>
                    <p>
                        Universal elements and all related indicia TM &amp; &#169; 2023 Universal
                        Studios. All rights reserved.
                    </p>

                    </div>}
                    {i18n.resolvedLanguage === 'es' && state && <div className="flex flex-col gap-4 text-x-gray2">
                    <p>
                        <strong>Detalles y restricciones:</strong>
                    </p>
                    <p>
                        Se aplican restricciones. Oferta limitada a miembros actuales de Xfinity
                        Rewards con cuenta al corriente de sus pagos mientras duren las
                        existencias. La oferta termina en 6/30/23. Comcast se reserva el derecho a
                        modificar o cancelar la oferta en cualquier momento. No se puede asignar o
                        transferir. Todos los canjes son finales. Para canjear la oferta, haga clic
                        en "Obtenga su paquete" y compre el paquete vacacional en el sitio de
                        Paquetes vacacionales de Xfinity Rewards de Universal Orlando. Ahorros en
                        base a una estancia de 7 noches. Se aplican restricciones.
                    </p>
                    <p>
                        WIZARDING WORLD y todas las marcas comerciales, personajes, nombres e
                        indicios relacionados son &#169; y &#8482; de Warner Bros. Entertainment
                        Inc. Derechos de publicaci&#243;n &#169; JKR. (s22)
                    </p>
                    <p>
                        Todos los precios, las inclusiones de paquetes y las opciones est&#225;n
                        sujetos a disponibilidad y a cambios sin previo aviso y se pueden aplicar
                        restricciones adicionales. Los errores se corregir&#225;n cuando se
                        descubran, y Universal Orlando y Universal Parks &amp; Resorts Vacations se
                        reservan el derecho de revocar cualquier oferta establecida y corregir
                        cualquier error, inexactitud u omisi&#243;n, ya sea que dicho error se
                        encuentre en un sitio web o en cualquier impresi&#243;n u otro anuncio
                        relacionado con estos productos y servicios.
                    </p>
                    <p>
                        <strong>
                            Combina y ahorra hasta $200 con el paquete vacacional de Xfinity
                            Rewards
                        </strong>
                    </p>
                    <p>
                        Descuento de la oferta Combina y ahorra para el Paquete vacacional de
                        Xfinity Rewards comprado en <a href="#" className="underline" >https://www.universalorlando.com/web-packages/en/us/vacation-packages/H605/xfinity-rewards-vacation-package </a>

                        y a trav&#233;s del Universal Orlando Guest Contact Center. V&#225;lido
                        para reservaciones desde ahora a 12/9/23 con viaje desde ahora a 12/18/23.
                        Para calificar para los ahorros, el Paquete vacacional de Xfinity Rewards
                        debe incluir como m&#237;nimo una estancia de 3 noches en un hotel y un
                        Boleto de 3 Parques O un Pase Anual o de Temporada de 3 Parques para cada
                        miembro del grupo de viaje. Oferta no v&#225;lida en paquetes vacacionales
                        con 1 noche o 2 noches de hotel O Boletos de 1 Parque o 2 Parques. El
                        Paquete vacacional de Xfinity Rewards requiere una estancia m&#237;nima
                        para reservar. Los ahorros se basan en la duraci&#243;n de la estancia.
                        Oferta v&#225;lida solo para nuevas reservaciones y no es v&#225;lida con
                        otras ofertas, descuentos, promociones o compras anteriores. Sin validez
                        donde est&#233; prohibido. Se pueden aplicar restricciones adicionales y
                        est&#225;n sujetas a cambios sin previo aviso. El descuento de la oferta
                        Combina y ahorra para el Paquete vacacional de Xfinity Rewards no se aplica
                        a: paquetes vacacionales ofrecidos a trav&#233;s de sitios web de subastas
                        en l&#237;nea, programas de membres&#237;a, descuentos corporativos,
                        tarifas grupales, tarifas promocionales ofrecidas para grupos que incluyen,
                        entre otros, asociaciones, militares, gobierno, asistentes a convenciones,
                        ofertas de viajes de tiempo compartido o promociones que Universal Parks
                        &amp; Resorts Vacations no ofrece al p&#250;blico en general. Universal
                        Parks &amp; Resorts Vacations se reserva el derecho de cambiar o eliminar
                        los ahorros aplicables como parte del descuento de la oferta Combina y
                        ahorra para el Paquete vacacional de Xfinity Rewards en cualquier momento y
                        sin previo aviso.
                    </p>
                    <p>
                        Para conocer los t&#233;rminos y condiciones completos, consulte <a href="#" className="underline" >https://www.universalorlando.com/web-packages/en/us/vacation-packages/H605/xfinity-rewards-vacation-package </a> &#8239;
                    </p>
                    <p>
                        Universal Parks &amp; Resorts Vacations est&#225; registrado en el Estado
                        de Florida como vendedor de viajes. N&#250;mero de registro ST-24215.
                    </p>
                    <p>
                        Elementos de Universal y todos los indicios relacionados TM y &#169; 2023
                        Universal Studios. Derechos Reservados.
                    </p>

                    </div>}
                </div>

                <div className="absolute right-4 top-4 cursor-pointer" onClick={hideModal}><CloseButton /></div>
            </div>
            <div className={`${state ? '': 'absolute bottom-0'} mt-8`}>
                    <img src="/imgs/modal_orlando.png" />
            </div>
        </ReactModal>

    )
}

export default Orlando