import React, { useState } from "react";
import ReactModal from "react-modal";
import { setLocalStorage } from "../Utills";
import ReactSelect2 from 'react-select2-wrapper';
import 'react-select2-wrapper/css/select2.css';


const stores = [
    `0001 - Galveston - Houston, TX`,
    `10000 - Retail Innovation Lab`,
    `1022 - Meridian, MS`,
    `1048 - Houma, LA`,
    `1102 - Atlanta, GA - Chamblee-Buckhead (XF)`,
    `1103 - Alpharetta, GA (XF)`,
    `1104 - Nashville, TN (XF)`,
    `1106 - Murfreesboro, TN (XF)`,
    `1107 - Savannah - Victory Crossing, GA (XF)`,
    `1108 - Augusta - Exchange, GA (XF)`,
    `1110 - Charleston, SC (XF)`,
    `1111 - Midtown Atlanta, GA (XF)`,
    `1112 - Hinesville, GA (XF)`,
    `1113 - Mallory Corners Shops - Cool Springs, TN (XF)`,
    `1114 - Lakeland Commons - Flowood, MS (XF)`,
    `1115 - Wedgewood Commons - Olive Branch, MS (XF)`,
    `1116 - Snellville, GA (XF)`,
    `1117 - Knoxville, TN (XF)`,
    `1118 - Acworth, GA (XF)`,
    `1120 - Barnes Crossing - Tupelo, MS (XF)`,
    `1122 - Tuscaloosa, AL (XF)`,
    `1123 - Mt. Pleasant, SC (XF)`,
    `1124 - Hendersonville, TN (XF)`,
    `1125 - Huntsville, AL (XF)`,
    `1127 - Atlanta, GA - Braves (XF)`,
    `1128 - Nashville, TN (XF) - Bellevue`,
    `1129 - Issa Crossing - Chattanooga, TN (XF)`,
    `1130 - North Decatur Square - Decatur,GA (XF)`,
    `1131 - Fayette Pavilion - Fayetteville, GA (XF)`,
    `1132 - McGowin Park - Mobile, AL (XF)`,
    `1133 - Saddle Creek - Germantown, TN (XF)`,
    `1134 - Northlake Village - Hermitage, TN (XF)`,
    `1135 - Regal Court - Shreveport, LA (XF)`,
    `1136 - Knoxville, TN (XF)`,
    `1137 - Renaissance At Colony Park - Ridgeland, MS (XF)`,
    `1139 -  Mullins Colony - Evans, GA (XF)`,
    `1140 - Fort Campbell - Fort Campbell, KY (XF)`,
    `1141 - The Avenues at East Cobb - Marietta, GA (XF)`,
    `1142 - Peachtree Corner - Norcross, GA (XF)`,
    `1143 - Twelve Oaks Shopping Center - Savannah, GA (XF)`,
    `1144 - Sandy Springs Plaza - Sandy Springs, GA (XF)`,
    `1145 - Camp Creek - Atlanta, GA (XF)`,
    `1146 - Memphis Bartlett- Memphis , TN (XF)`,
    `1147 - MEDCARE Plaza - West Ashley, SC (XF)`,
    `1148 - Memphis, TN (XF)`,
    `1149 - Pecanland Commons - Monroe, LA (XF)`,
    `1150 - Century Farms - Antioch, TN (XF)`,
    `1150 - Century Farms - Antioch, TN (XF)`,
    `1151 - Camp Creek Marketplace - Atlanta, GA (XF)`,
    `1234 - Quincy, IL`,
    `1301 - Schaumburg, IL (XF)`,
    `1302 - Morton Grove, IL (XF)`,
    `1303 - Merrillville, IN (XF)`,
    `1304 - Bolingbrook, IL (XF)`,
    `1305 - Oak Brook, IL (XF)`,
    `1306 - Batavia, IL (XF)`,
    `1307 - North Ave, IL (XF)`,
    `1308 - East Peoria, IL (XF)`,
    `1309 - Crystal Lake, IL (XF)`,
    `1310 - Vernon Hills, IL (XF)`,
    `1311 - Orland Park, IL (XF)`,
    `1312 - Joliet, IL (XF)`,
    `1313 - Lakeview, IL (XF)`,
    `1314 - Berwyn Gateway Plaza - Berwyn, IL (XF)`,
    `1315 - Chicago, IL (XF) - Hyde Park`,
    `1316 - Benton Harbor, MI (XF)`,
    `1317 - Forsyth, IL (XF)`,
    `1318 - Chicago, IL (XF) - West Loop`,
    `1320 - Springfield, IL (XF)`,
    `1321 - Arlington Heights, IL (XF)`,
    `1322 - Gurnee Mills - Gurnee, IL (XF)`,
    `1324 - Marketplace Shopping Center - Champaign, IL (XF)`,
    `1325 - Harlem Irving Plaza - Norridge, IL (XF)`,
    `1327 - Shoppes at College Hills - Normal, IL (XF)`,
    `1328 - Stratford Crossing - Bloomingdale, IL (XF)`,
    `1329 - Millennium Market Center - Rockford, IL (XF)`,
    `1330 - Algonquin Commons - Algonquin, IL (XF)`,
    `1331 - Marshfield Plaza - Chicago, IL (XF)`,
    `1333 - Peoria, IL (XF)`,
    `1334 - Schaumburg, IL (XF)`,
    `1334 - Schaumburg, IL (XF)`,
    `1334 - Schaumburg, IL (XF)`,
    `1335 - Springbrook Prairie Pavilion - Naperville, IL (XF)`,
    `1336 - Riverside Square - Chicago, IL (XF)`,
    `1337 - Homewood Square - Homewood, IL (XF)`,
    `1338 - Rice Lake Square - Wheaton, IL (XF)`,
    `1339 - Elgin, IL (XF)`,
    `1339 - Elgin, IL (XF)`,
    `1428 - Dothan, AL`,
    `1430 - Sebring, FL`,
    `1432 - Key West, FL`,
    `1442 - Key Largo, FL`,
    `1501 - Kendall, FL (XF)`,
    `1502 - Jacksonville West - S. Ortega, FL (XF)`,
    `1504 - Jacksonville East - Pablo Creek, FL (XF)`,
    `1505 - Jacksonville South - Claire Ln, FL (XF)`,
    `1506 - Pompano Beach, FL (XF)`,
    `1507 - Naples North, FL (XF)`,
    `1508 - Naples South, FL (XF)`,
    `1509 - Wellington, FL (XF)`,
    `1510 - Plantation, FL (XF)`,
    `1511 - Miami (West Flagler), FL (XF)`,
    `1512 - Sarasota, FL (XF)`,
    `1513 - Ft Myers, FL (XF)`,
    `1514 - Pembroke Pines, FL (XF)`,
    `1515 - Ft. Lauderdale, FL (XF)`,
    `1516 - St. Augustine, FL (XF)`,
    `1517 - Tallahassee, FL (XF)`,
    `1518 - Delray Beach, FL (XF)`,
    `1519 - Venice, FL (XF)`,
    `1520 - Cape Coral, FL (XF)`,
    `1521 - Midtown Miami, FL (XF)`,
    `1522 - Lady Lake, FL (XF)`,
    `1523 - Estero, FL (XF)`,
    `1524 - Aventura, FL (XF)`,
    `1525 - Alton Town Center - Palm Beach Gardens, FL (XF)`,
    `1526 - Marketplace at the Outlets - West Palm Beach, FL (XF)`,
    `1527 - Colonial Palms Plaza - Palmetto Bay, FL (XF)`,
    `1529 - Shoppes at Jensen Beach - Stuart, FL (XF)`,
    `1530 - Indian River Square - Vero Beach, FL (XF)`,
    `1531 - Dania Beach, FL  (XF)`,
    `1532 - Boca Raton, FL (XF)`,
    `1533 -  Bay City Point  - Panama City, FL (XF)`,
    `1534 - Port Charlotte, FL (XF)`,
    `1536 - Sedano's Center - Hialeah, FL (XF)`,
    `1537 - Leesburg, FL (XF)`,
    `1538 - St Johns Town Center - Jacksonville, FL (XF)`,
    `1539 - Sarasota Pavilion - Sarasota, FL (XF)`,
    `1539 - Sarasota Pavilion - Sarasota, FL (XF)`,
    `1540 - Boynton Beach, FL (XF)`,
    `1701 - Sterling Heights, MI (XF)`,
    `1703 - Greenwood, IN (XF)`,
    `1705 - Plymouth, MI (XF)`,
    `1706 - Grand Rapids East, MI (XF)`,
    `1707 - Indianapolis, IN (XF)`,
    `1708 - Troy, MI (XF)`,
    `1709 - Jackson, MI (XF)`,
    `1710 - Flint, MI (XF)`,
    `1711 - Bloomington, IN (XF)`,
    `1712 - Granger, IN (XF)`,
    `1713 - Ann Arbor, MI (XF)`,
    `1714 - Paducah, KY (XF)`,
    `1715 - South Street - Lafayette, IN (XF)`,
    `1716 - Eastwood Towne Center - Lansing, MI (XF)`,
    `1717 - Harper Ave - St Clair Shores, MI (XF)`,
    `1720 - Fairlane Town Center - Dearborn, MI (XF)`,
    `1722 - Woodward Gardens - Detroit, MI (XF)`,
    `1723 - Muskegon - Muskegon, MI (XF)`,
    `1724 - Rivertown Cirlce - Grandville, MI (XF)`,
    `1726 - Gateway Market Place- Detroit, MI (XF)`,
    `1727 - Jefferson Pointe - Fort Wayne, IN (XF)`,
    `1728 - Taylor Town Center - Taylor, MI (XF)`,
    `1729 - Green Oak Village Place - Brighton, MI (XF)`,
    `1730 - Starlight Center - Elizabethtown, KY (XF)`,
    `1731 - Fort Wayne -  IN (XF)`,
    `1732 - Bloomfield Hills, MI (XF)`,
    `1733 - Shelby Town Center- Shelby Charter Twp, MI (XF)`,
    `1734 - Traders Point - Indianapolis, IN (XF)`,
    `1735 - Rochester Hills - MI (XR)`,
    `1736 - Markland Mall- Kokomo, IN (XF)`,
    `1737 - Woodland Corner Shops - Grand Rapids, MI (XF)`,
    `2010 - Richmond Boulevard, VA`,
    `2017 - Huntington, WV`,
    `2024 - Bluefield, WV`,
    `2039 - Lewes, DE`,
    `2045 - Ocean City, MD`,
    `2104 - Jessup, MD (XF)`,
    `2106 - Frederick, MD (XF)`,
    `2108 - Silver Spring, MD (XF)`,
    `2109 - Midlothian, VA (XF)`,
    `2110 - Richmond Green Gate, VA (XF)`,
    `2111 - Baltimore Rotunda, MD (XF)`,
    `2112 - Charlottesville, VA (XF)`,
    `2114 - Largo, MD (XF)`,
    `2115 - Arlington, VA (XF)`,
    `2116 - Owings Mills, MD (XF)`,
    `2118 - Ashburn, VA (XF)`,
    `2119 - Fredericksburg, VA (XF)`,
    `2120 - Riverdale Park, MD (XF) (College Park)`,
    `2121 - Danville, VA (XF)`,
    `2123 - Dover, DE (XF)`,
    `2124 - Washingtonian - Gaithersburg, MD (XF)`,
    `2125 - Annapolis Town Center - Annapolis, MD (XF)`,
    `2126 - Avenue at White Marsh- White Marsh, MD (XF)`,
    `2127 - Stonebridge at Potomac Town Center - Woodbridge, VA (XF)`,
    `2130 - The Collective at Canton - Baltimore, MD (XF)`,
    `2131 - Shoppes at Fox Chase - Alexandria, VA (XF)`,
    `2132 - Largo Plaza - Largo, MD (XF)`,
    `2245 - Port Murray, NJ`,
    `2303 - Mt. Laurel, NJ (XF)`,
    `2305 - Havertown, PA (XF)`,
    `2306 - West Orange, NJ (XF)`,
    `2307 - Main Street at Exton - Exton, PA (XF)`,
    `2308 - Market Square - Wilmington, DE (XF)`,
    `2309 - Toms River, NJ (XF)`,
    `2310 - Cherry Hill, NJ (XF)`,
    `2311 - Mays Landing, NJ (XF)`,
    `2312 - Lawrenceville, NJ (XF)`,
    `2313 - Rio Grande Plaza - Rio Grande, NJ (XF)`,
    `2314 - Center Island - Union, NJ (XF)`,
    `2315 - King of Prussia, PA (XF)`,
    `2317 - Turnersville, NJ (XF)`,
    `2318 - Millville, NJ (XF)`,
    `2319 - Doc Cramer - Manahawkin, NJ (XF)`,
    `2320 - Deptford Mall - Deptford , NJ (XF)`,
    `2322 - Newport Centre - Jersey City, NJ (XF)`,
    `2323 - North Wales Crossing - North Wales, PA (XF)`,
    `2324 - City Ave - Philadelphia, PA (XF)`,
    `2325 - St. Georges Crossing - Woodbridge, NJ (XF)`,
    `2326 - Christiana Mall - Newark, DE (XF)`,
    `2327 - Walnut Street - Philadelphia, PA (XF)`,
    `2328 - Northeast Tower - Philadelphia, PA (XF)`,
    `2329 - Upland Square - Pottstown, PA (XF)`,
    `2330 - Consumer Center -West Long Branch, NJ (XF)`,
    `2331 - Northeast Shopping Center- Philadelphia, PA (XF)`,
    `2332 - Oxford Valley Mall - Langhorne, PA (XF)`,
    `2334 - Brunswick Square Mall - East Brunswick, NJ (XF)`,
    `2335 - Promenade at Upper Dublin - Dresher, PA (XF) (Willow Grove)`,
    `2401 - New Bedford, MA (XF)`,
    `2403 - Fall River, MA`,
    `2419 - Hyde Park, MA`,
    `2423 - Dorchester, MA`,
    `2426 - Roslindale, MA`,
    `2428 - South Yarmouth, MA`,
    `2439 - Brunswick, ME`,
    `2445 - Orleans, MA`,
    `2453 - Nantucket, MA`,
    `2456 - Norwood, MA`,
    `2457 - Edgartown, MA`,
    `2459 - Provincetown, MA`,
    `2504 - Nashua, NH (XF)`,
    `2505 - Danvers, MA (XF)`,
    `2507 - Mansfield, MA (XF)`,
    `2508 - Cape Roads Crossing - Raynham, MA (XF)`,
    `2509 - Burlington, MA (XF)`,
    `2512 - Methuen, MA (XF)`,
    `2513 - Lowell, MA (XF)`,
    `2514 - Plymouth, MA (XF)`,
    `2516 - Brockton, MA (XF)`,
    `2517 - Allston, MA (XF)`,
    `2518 - Mall of New Hampshire - Manchester, NH (XF)`,
    `2520 - Fresh Pond Shopping Center - Cambridge, MA (XF)`,
    `2521 - Charles River Center - Bellingham, MA (XF)`,
    `2522 - Assembly Square - Somerville, MA (XF)`,
    `2528 - Downtown Crossing- Boston, MA (XR)`,
    `2529 - RK Center- Framingham, MA (XF)`,
    `2530 - Lake Shore Marketplace - Gilford, NH (XF)`,
    `2531 - Liberty Square - Falmouth, MA (XF)`,
    `2532 - University Station, Westwood, MA (XF)`,
    `2701 - Morgantown, WV (XF)`,
    `2702 - York, PA (XF)`,
    `2704 - Reading, PA (XF)`,
    `2705 - Dickson City, PA (XF)`,
    `2707 - Washington, PA (XF)`,
    `2708 - Harrisburg, PA (XF)`,
    `2709 - Greensburg, PA (XF)`,
    `2710 - North Fayette, PA (XF)`,
    `2711 - Upper St. Clair, PA (XF)`,
    `2712 - Williamsport, PA (XF)`,
    `2713 - The Cube East Liberty - Pittsburgh, PA (XF)`,
    `2714 - Monroeville - Monroeville, PA (XF)`,
    `2715 - Chambersburg Crossing - Chambersburg, PA (XF)`,
    `2716 - The Highlands - Triadelphia, WV (XF)`,
    `2717 - Shoppes at Belmont - Lancaster, PA (XF)`,
    `2718 - Waterfront - Homestead, PA (XF)`,
    `2719 - North Atherton - State College, PA (XF)`,
    `2720 - Mechanicsburg, PA (XF)`,
    `2808 - Rutland, VT`,
    `2901 - Danbury, CT (XF)`,
    `2903 - West Hartford, CT (XF)`,
    `2904 - Groton, CT (XF)`,
    `2905 - North Haven, CT (XF)`,
    `2907 - Springfield, MA (XF)`,
    `2908 - Waterbury, CT (XF)`,
    `2910 - Holyoke Mall - Holyoke, MA (XF)`,
    `2911 - Connecticut Commons - Plainville, CT (XF)`,
    `2912 - Finney Crossing - Williston, VT (XF)`,
    `3022 - Lompoc, CA`,
    `3024 - Ukiah, CA`,
    `3102 - Oakland, CA (XF)`,
    `3103 - Union City, CA (XF)`,
    `3105 - Richmond, CA (XF)`,
    `3106 - Santa Rosa, CA (XF)`,
    `3109 - Vallejo, CA (XF)`,
    `3110 - Truxel, CA (XF)`,
    `3111 - Modesto, CA (XF)`,
    `3113 - Dublin, CA (XF)`,
    `3114 - North Fresno, CA (XF)`,
    `3115 - San Jose, CA - Westgate (XF)`,
    `3116 - Tracy, CA (XF)`,
    `3120 - Brentwood, CA (XF)`,
    `3121 - Palo Alto, CA (XF)`,
    `3122 - San Rafael, CA (XF)`,
    `3123 - Santa Cruz, CA (XF)`,
    `3124 - SF Geary, CA (XF)`,
    `3125 - Delta Shores, CA (XF)`,
    `3126 - San Jose, CA - Sun Garden (XF)`,
    `3127 - Bay Street - Emeryville, CA (XF)`,
    `3128 - Vacaville, CA (XF)`,
    `3129 - San Mateo, CA (XF)`,
    `3130 - Santa Maria, CA (XF)`,
    `3131 - Daly City, CA (XF)`,
    `3132 - Chico, CA (XF)`,
    `3133 - Roseville, CA (XF)`,
    `3134 - Concord2, CA (XF)`,
    `3135 - San Jose, CA - Brokaw (XF)`,
    `3136 - Packwood Creek West - Visalia, CA (XF)`,
    `3137 - Northridge Mall - Salinas, CA (XF)`,
    `3138 - Yuba City Marketplace - Yuba City, CA (XF)`,
    `3139 - Napa Crossing South - Napa, CA (XF)`,
    `3141 - Broadstone Plaza - Folsom, CA (XF)`,
    `3142 - Market Square - Sacramento, CA (XF)`,
    `3144 - Fashion Fair Mall - Fresno, CA (XF)`,
    `3145 - Stonestown Galleria - San Francisco, CA (XF)`,
    `3146 - City Line - Sunnyvale, (XF)`,
    `3149 - Potrero Center - San Francisco, CA (XF)`,
    `3150 - Stonecreek Village - Stockton, CA (XF)`,
    `3151 - Vallejo Corners - Vallejo, CA (XF)`,
    `3152 - Tracy Marketplace - Tracy, CA (XF)`,
    `3152 - Tracy Marketplace - Tracy, CA (XF)`,
    `3152 - Tracy Marketplace - Tracy, CA (XF)`,
    `3153 - Lompoc Center - Lompoc, CA (XF)`,
    `3250 - Houston (Voss), TX (XF)`,
    `3251 - League City, TX (XF)`,
    `3253 - Katy, TX (XF)`,
    `3256 - Humble,TX (XF)`,
    `3257 - Pasadena, TX (XF)`,
    `3258 - Cypress, TX (XF)`,
    `3259 - Baytown Marketplace - Baytown, TX (XF)`,
    `3260 - University Commons - Sugarland, TX (XF)`,
    `3262 - The Woodlands Mall - The Woodlands, TX (XF)`,
    `3263 - Willowbrook Mall - Houston, TX (XF)`,
    `3264 - Shadow Creek Ranch - Pearland, TX (XF)`,
    `3266 - Northwest Marketplace - Houston, TX (XF)`,
    `3267 - Grand Morton Town Center - Katy, TX (XF)`,
    `3314 - Avon, CO`,
    `3320 - Aspen, CO`,
    `3352 - Lakewood, CO (XF)`,
    `3353 - Colorado Springs, CO (XF)`,
    `3354 - Thornton, CO (XF)`,
    `3355 - Las Cruces, NM (XF)`,
    `3356 - Boulder, CO (XF)`,
    `3357 - Centennial, CO (XF)`,
    `3358 - Denver, CO (XF)`,
    `3359 - Loveland, CO (XF)`,
    `3360 - Arvada, CO (XF)`,
    `3361 - Longmont, CO (XF)`,
    `3362 - Pueblo, CO (XF)`,
    `3363 - Fort Collins, CO (XF)`,
    `3364 - Barnes Marketplace - Colorado Springs, CO (XF)`,
    `3365 - Gardens on Havana - Aurora, CO (XF)`,
    `3367 - Animas Valley Mall - Farmington, NM (XF)`,
    `3368 - Prairie Center - Brighton, CO (XF)`,
    `3369 - Plaza Santa Fe - Santa Fe, NM (XF)`,
    `3370 - Promenade at Castle Rock - Castle Rock, CO (XF)`,
    `3371 - Crown Point - Parker, CO (XF)`,
    `3372 - The Shops at NorthCreek - Denver, CO (XF)`,
    `3373 - Orchard Town Center - Westminster, CO (XF)`,
    `3374 - Shops at Walnut Creek -Westminster, CO (XF)`,
    `3403 - Park City, UT`,
    `3453 - Orem, UT (XF)`,
    `3455 - Draper, UT (XF)`,
    `3457 - Salt Lake City, UT (XF)`,
    `3458 - Academy Square - Logan, UT (XF)`,
    `3459 - Arizona Pavilions - Tucson, AZ (XF)`,
    `3460 - Jordan Landing - West Jordan, UT (XF)`,
    `3461 - Fashion Plaza - Murray, UT (XF)`,
    `3463 - Summit Place - Silverthorne, CO (XF)`,
    `3464 - Glenwood Meadows - Glenwood Springs, CO (XF)`,
    `3465 - Tucson, AZ (XF)`,
    `3466 - Farmington, UT (XF)`,
    `3468 - Pavilions at San Mateo - Albuquerque, NM (XF)`,
    `3469 - Steamboat Springs, CO (XF)`,
    `3469 - Steamboat Springs, CO (XF)`,
    `3550 - Portland, OR (XF)`,
    `3551 - Vancouver Plaza - Vancouver, WA (XF)`,
    `3554 - Hillsboro, OR (XF)`,
    `3556 - Eugene, OR (XF)`,
    `3557 - Tigard, OR (XF)`,
    `3558 - Gresham, OR (XF)`,
    `3559 - Corvallis Market Center - Corvallis, OR (XF)`,
    `3560 - Clackamas Town Center - Happy Valley, OR (XF)`,
    `3561 - Willamette Town Center Salem, OR (XF)`,
    `3650 - Federal Way, WA (XF)`,
    `3651 - Factoria, WA (XF)`,
    `3652 - Auburn, WA (XF)`,
    `3653 - Redmond, WA (XF)`,
    `3654 - Puyallup, WA (XF)`,
    `3655 - Lynnwood, WA (XF)`,
    `3656 - Everett, WA (XF)`,
    `3657 - Spokane, WA (XF)`,
    `3659 - South Lake Union, WA (XF)`,
    `3660 - South Center, WA (XF)`,
    `3661 - Bellingham, WA (XF)`,
    `3662 - Olympia, WA (XF)`,
    `3664 - Bellevue Square - Bellevue, WA (XF)`,
    `3665 - The Trails at Silverdale - Silverdale, WA (XF)`,
    `3667 - Olympic Gateway Plaza - Aberdeen, WA (XF)`,
    `3669 - Spokane Valley, WA (XF)`,
    `3670 - Tacoma, WA (XF)`,
    `3671 - Northgate Station - Seattle, WA (XF)`,
    `3705 - Manitowoc, WI`,
    `3751 - Brooklyn Park, MN (XF)`,
    `3752 - Roseville, MN (XF)`,
    `3753 - Independence, MO (XF)`,
    `3754 - St. Paul, MN (XF)`,
    `3755 - Woodbury, MN (XF)`,
    `3756 - St. Louis Park, MN (XF)`,
    `3757 - Eagan, MN (XF)`,
    `3758 - Ridgedale Center - Minnetonka, MN (XF)`,
    `3759 - Olathe Station North - Olathe, KS (XF)`,
    `3781 - Rue De France Shopping Center - Edina, MN (XF)`,
    `5006 - Reston, VA (XF)`,
    `5009 - Las Vegas, NM (XF) Pop-Up`,
    `5030 - COR Retail Events`,
    `5031 - COR Retail Events`,
    `5032 - COR Retail Events`,
    `5033- COR Retail Events`,
    `5034 - COR Retail Events`,
    `5035 - COR Retail Events`,
    `5040 - XPod - Xfinity on Tour`,
    `5041 - XPod - Xfinity on Tour`,
    `5042 - XPod- Xfinity on Tour`,
    `51000 - Tamarac - Tamarac, FL (XF)`,
    `51002 - Coconut Creek - Coconut Creek, FL (XF)`,
    `51003 - Ivernarry Falls Center - Lauderhill, FL (XF)`,
    `51004 - Green Acres - Greenacres, FL (XF)`,
    `51005 - Southern Palm Crossing - Royal Palm Beach, FL (XF)`,
    `51006 - Gateway at Sawgrass, Plantation, FL (XF)`,
    `51007 - Pavilion at Durbin Park- St Johns, FL (XF)`,
    `51009 - Oak Ridge, TN (XF)`,
    `51011 - Greeneville Commons - Greeneville, TN (XF)`,
    `51012 - Island Walk Shopping Center- Fernandina Beach, FL (XF)`,
    `51013 - Knightboxx Village- Orange Park, FL (XF)`,
    `51014 - Port St. Lucie, FL (XF)`,
    `51015 - Brunswick, GA (XF)`,
    `51016 - Jacksonville, FL (River City)`,
    `51017 - Hammond Mills, IN (XF) `,
    `52000 - Seabrook Commons DDR - Seabrook, NH (XF)`,
    `52001 - Dover Crossing - Dover, NH (XF)`,
    `52003 - East Boston - East Boston, MA (XF)`,
    `52004 - Weymouth - Weymouth, MA (XF)`,
    `52005 - The Shops at Waldorf Center -Waldorf, MD (XF)`,
    `52006 - Quincy Commons - Roxbury, MA (XF)`,
    `52007 - Harrisonburg, VA (XF)`,
    `52008 - Winchester Station- Winchester, VA (XF)`,
    `52009 - The Commons- Martinsburg, WV (XF)`,
    `52010 - Lynchburg, VA (XF)`,
    `52013 - West Lebanon, NH (XF)`,
    `52016 - Marketplace Shopping Center- Christiansburg, VA (XF)`,
    `52017 - Twin City Shopping Center- Leominster, MA (XF)`,
    `52018 - Shops at Saugus- Saugus, MA (XF)`,
    `52019 - Cape Cod Mall - Hyannis, MA (XF)`,
    `52020 - Virginia Gateway - Gainesville, VA (XF)`,
    `52022 - Tuscan Village North - Salem, NH (XF)`,
    `52023 - Canton, CT (XF)`,
    `52024 - Salisbury Promenade - Salisbury, MD (XF)`,
    `52025 - The Corner Shops at Chesterfield Towne Center - Richmond, VA (XF)`,
    `52026 - Durgin Square - Portsmouth, NH (XF)`,
    `52027 - Southpark Crossing - Colonial Heights, VA (XF)`,
    `52030 - Festival at Bel Air - Bel Air, MD (XF)`,
    `52031 - Tri City Plaza - Vernon, CT (XF)`,
    `52032 - Middletown, CT (XF)`,
    `52033 - Capital Shopping Center- Concord, NH (XF)`,
    `52034 - Derby, CT (BP)`,
    `52035 - Tanger Outlets Rehoboth Beach Seaside - Rehoboth Beach, DE (XF)`,
    `52036 - Towson, MD (XF)`,
    `52038 - Branford, CT (XF)`,
    `52040 - Somers Point, NJ (XF)`,
    `52041 - York, PA (XF)`,
    `52042 - Westfield, MA (XF)`,
    `53000 - Kemah Marketplace - Kemah, TX (XF)`,
    `53001 - Tomball Marketplace - Tomball, TX (XF)`,
    `53004 -  Spring, TX (XF)`,
    `53007 - Cinco Ranch - Katy, TX (XF)`,
    `53008 - Rice Village - Houston, TX (XF)`,
    `53009 - Webster/Baybrook - Webster, TX (XF)`,
    `53010 - Memorial Center- Houston, TX`,
    `53011 - West Chase - Houston, TX (XF)`,
    `53012 - The Heights - Houston, TX (XF)`,
    `53013 - Bellaire Town Center - Bellaire, TX (XF)`,
    `53014 - Gulfgate Court - Houston, TX (XF)`,
    `53015 - Wallisville - Houston, TX (XF)`,
    `53016 - Copperfield, TX (XF)`,
    `53017 - Stafford - Houston, TX (XF)`,
    `53018 - Lake Jackson, TX (XF)`,
    `53019 - Pearland, TX (East) (XF)`,
    `53020 - Galveston, TX (XF)`,
    `53021 - Fulshear, TX (XF)`,
    `53022 - Richmond, TX (Aliana) (XF)`,
    `53023 - East Humble, TX (Atascocita) (XF)`,
    `53023 - Humble, TX (Atascocita) (XF)`,
    `53024 - Houston, TX (Northline) (XF)`,
    `53025 - Fondren -  Houston, TX (XF)`,
    `53026 - Fry Road (XF)`,
    `54000 - Douglasville - Douglasville, GA (XF)`,
    `54001 -  Ridgeway Parkway - Woodstock, GA (XF)`,
    `54003 - Cumming - Cumming, GA (XF)`,
    `54004 - Kennesaw - Kennesaw, GA (XF)`,
    `54006 - Shopps at Turner Hill - Lithonia, GA (XF)`,
    `54007 - Conyers Heritage Square - Conyers, GA (XF)`,
    `54008 - Morrow Station - Morrow, GA (XF)`,
    `54009 - Austell - Marietta, GA (XF)`,
    `54010 - Hixson Crossing - Hixson, TN (XF)`,
    `54011 - Eastbend Shopping Center, Rome, GA (XF)`,
    `54012 - Hiram Pavilion - Hiram, GA (XF)`,
    `54013 - McCain Plaza- North Little Rock, AR (XF)`,
    `54014 - Park Avenue Shopping Center - Little Rock, AR (XF)`,
    `54015 - Cox Creek Shopping Center, Florence, AL (XF)`,
    `54016 - Coosa Town Center, Gadsden, AL (XF)`,
    `54017 - Shops at Turtle Creek- Hattiesburg, MS 39402`,
    `54018 - Shops on Frontage - Meridian, MS (XF)`,
    `54020 - Madison, TN (XF)`,
    `54021 - Pooler, GA (XF)`,
    `54109 - Augusta, GA (Windsor Spring) (XF)`,
    `55000 - Valparaiso Marketplace - Valparaiso, IN (XF)`,
    `55001 - Kildeer Village Square - Kildeer, IL (XF)`,
    `55002 - Tinley Park - Tinley Park, IL (XF)`,
    `55003 - Oakland Shopping Center - Dekalb, IL`,
    `55004 - Patriot Marketplace - Glenview, IL (XF)`,
    `55005 - Frankfort - Frankfort, IL (XF)`,
    `55006 - Northfield Center - Elkhart, IN (XF)`,
    `55007 - Hammond/Woodmar Center - Hammond, IN (XF)`,
    `55008 - Evanston - Evanston, IL (XF)`,
    `55009 - Uptown - Chicago, IL (XF)`,
    `55010 - Evergreen Park - Evergreen Park, IL (XF)`,
    `55011 - Cicero - Cicero, IL (XF)`,
    `55013 - Port Huron - Fort Gratiot, MI (XF)`,
    `55014 - Ypsilanti - Ypsilanti, MI (XF)`,
    `55015 - Crossroads Village - Canton, MI (XF)`,
    `55016 - West Ridge Shopping Center - Westland, MI (XF)`,
    `55017 - Southfield - Southfield, MI (XF)`,
    `55018 - Eastgate Shopping Center  - Roseville, MI (XF)`,
    `55019 - Lansing, MI (BP)`,
    `55020 - Cherry Hill Plaza - Inkster, MI (XF)`,
    `55021 - Southtown Centre -Anderson,IN (XF)`,
    `55022 - Minges Creek Plaza - Battle Creek, MI (XF)`,
    `55023 - Richmond, IN (BP)`,
    `55024 - Columbus, IN (XF)`,
    `55025 - Gables of Avon - Avon, IN (XF)`,
    `55026 - Muncie Shoppes - Muncie, IN (XF)`,
    `55027 - Northfield Square Mall, Bourbonnais. IL (XF)`,
    `56000 - Fox Chapel - Pittsburgh, PA (XF)`,
    `56001 - Bridgeville - Bridgeville, PA (XF)`,
    `56002 - Irwin - Irwin, PA (XF)`,
    `56003 - Pleasant Hills - Pittsburgh, PA (XF)`,
    `56005 - Chippewa Town Center - Beaver Falls, PA (XF)`,
    `56007 - Cedar Crest Square, Lebanon, PA (XF)`,
    `56008 - Richland 315 - Wilkes-Barre, PA (XF)`,
    `56009 - Hershey Square- Hummelstown, PA (XF)`,
    `56010 - South Towne Plaza- Indiana, PA (XF)`,
    `56012 - North Point Plaza - Hanover, PA (XF)`,
    `56013 - Richland Crossings - Quakertown, PA (XF)`,
    `56014 - Hillsborough Promenade- Hillsborough, NJ (XF)`,
    `56015 - Marlton Crossing - Marlton, NJ (XF)`,
    `56016 - Wishing Well Plaza - Burlington, NJ (XF)`,
    `56017 - Pittsburgh, PA (XF) - McKnight Road`,
    `56018 - Carlisle Crossing - Carlisle, PA (XF)`,
    `56019 - Flemington, NJ (XF)`,
    `59000 - Southwest Plaza - Littleton, CO (XF)`,
    `59001 - Applewood Village - Wheat Ridge, CO (XF)`,
    `59002 - Greeley - Greeley, CO (XF)`,
    `59003 - Northfield Stapleton - Denver, CO (XF)`,
    `59004 - Rancho Cordova Town Center - Rancho Cordova, CA (XF)`,
    `59005 - Laguna Crossroads - Elk Grove, CA (XF)`,
    `59006 - Davis - Davis, CA (XF)`,
    `59007 - Downtown Sacramento - Sacramento, CA (XF)`,
    `59008 - Southglenn/Cherry Hills - Greenwood Village, CO (XF)`,
    `59009 - South Aurora - Aurora, CO (XF)`,
    `59010 - Sunrise Plaza - Citrus Heights, CA (XF)`,
    `59011 - River Point at Sheridan - Sheridan, CO (XF)`,
    `59012 - Ballard - Seattle, WA (XF)`,
    `59013 - Kent - Kent, WA (XF)`,
    `59015 - Woodinville - Woodinville, WA (XF)`,
    `59016 - Hazel Dell - Vancouver, WA (XF)`,
    `59017 - Twin Cities Shopping Center - Longview, WA (XF)`,
    `59018 - Eastport Plaza - Portland, OR (XF)`,
    `59019 - Sunset Esplanade - Hillsboro, OR (XF)`,
    `59020 - Shingle Creek - Brooklyn Center, MN (XF)`,
    `59021 - Riverdale Crossing - Coon Rapids, MN (XF)`,
    `59022 - Village of Blaine - Blaine, MN (XF)`,
    `59024 - South Fresno - Fresno, CA (XF)`,
    `59025 - Springbrook Plaza, Newberg, OR (BP)`,
    `59026 - The Shoppes at Arbor Lakes - Maple Grove, MN (BP)`,
    `59027 - W. St. Paul, MN 55118`,
    `59028 - The Marketplace at Hanford - Hanford, CA (XF)`,
    `59029 - The Trading Post - Clovis, CA(XF)`,
    `59030 - Vadnais Square - Vadnais Heights, MN (XF)`,
    `59031 - Hunter's Crossing - American Fork, UT (XF)`,
    `59032 - Sugarhouse  - Salt Lake City, UT (XF)`,
    `59033 - Mountain View Village -  Riverton, UT (XF)`,
    `59034 - Highbury Centre - West Valley City, UT (XF)`,
    `59035 - Lloyd District- Portland, OR (XF)`,
    `59036 - Olympic Town Center - Gig Harbor, WA (XF)`,
    `59037 - Village at Totem Lake - Kirkland, WA (XF)`,
    `59038 -  Diamond Plaza - Ogden, UT (XF)`,
    `59039 - West Fresno, CA (Xf)`,
    `59041 - Sunwest Village- Lodi, CA (XF)`,
    `59043 - Lakewood, WA (XF)`,
    `59044 - Cascade Station - Portland, OR (XF)`,
    `59045 - Westwood Village - Seattle, WA (XF)`,
    `59046 - Broadmoor Towne Center - Colorado Springs, CO (XF)`,
    `59047 - Stadium Center -Manteca, CA (XF)`,
    `59048 - Pacific Commons - Fremont, CA (XF)`,
    `59049 - East Vancouver - Vancouver, WA (XF)`,
    `59050 - Frontier Village - Lake Stevens, WA (XF)`,
    `59051 - Covington Square - Kent, WA (XF)`,
    `59052 - Cross Court Plaza - Burlington, WA (XF)`,
    `59053 - Parkway West- Springfield, OR (XF)`,
    `59054 - Merced, CA`,
    `59055 - Albuquerque, NM (Uptown)`,
    `59056 - Albuquerque, NM (Cottonwood) (BP)`,
    `59057 - Monterey/Sand City, CA (BP)`,
    `59058 - Los Banos, CA (XF)`,
    `59059 - Petaluma, CA (XR)`,
    `59060 - Mill Creek, WA (XF)`,
    `59061 - Beaverton/Cedar Hills (XF)`,
    `59062 - Olympia, WA (XF)`,
    `59063 - Diablo Park - San Ramon, CA (XF)`,
    `59064 - Aurora Marketplace - Edmonds, WA (XF)`,
    `59065 - Homestead Square Shopping Center - Cupertino, CA (XF)`,
    `59066 - The Broadway, Oakland, CA (XF)`,
    `59067 - Century Plaza - Pittsburg, CA (XF)`,
    `59068 - Fairfield, CA (XF)`,
    `59068 - Fairfield, CA (XF)`,
    `59069 - Mountain View, CA (XF)`,
    `59070 - San Leandro, CA (XF)`,
    `59070 - San Leandro, CA (XF)`,
    `59072 - Albuquerque, NM (Paseo)  (NE) (XF)`,
    `59073 - San Jose, CA (Eastridge) (XF)`,
    `59074 - Aurora, CO (XF)`,
    `59074 - Aurora, CO (XF)`,
    `59074 - Aurora, CO (XF)`,
    `59075 - Eden Prairie, MN (XF)`,
    `59075 - Eden Prairie, MN (XF)`,
    `59075 - Eden Prairie, MN (XF)`,
    `59076 - Issaquah, WA (XF)`,
    `59076 - Issaquah, WA (XF)`,
    `59076 - Issaquah, WA (XF)`,
    `59077 - Livermore, CA (XF)`,
    `59078 - Walnut Creek, CA (XF)`,
    `59078 - Walnut Creek, CA (XF)`,
    `59078 - Walnut Creek, CA (XF)`,
    `59079 - El Cerrito, CA (XF)`,
    `59080 - Renton, WA (XF)`,
    `59081 - Corte Madera, CA (XF)`,
    `59081 - Corte Madera, CA (XF)`,
    `59082 - Rohnert Park, CA (XF)`,
    `59083 - Antelope, CA (XF)`,
    `59083 - Antelope, CA (XF)`,
    `59083 - Antelope, CA (XF)`,
    `59085 - Santa Clara, CA (XF)`,
    `59085 - Santa Clara, CA (XF)`,
    `59085 - Santa Clara, CA (XF)`,
    `59086 - Albany, OR (XF)`,
    `59087 - Keizer, OR - Keizer Station (XF)`,
    `59087 - Keizer, OR - Keizer Station (XF)`,
    `59088 - Layton, UT (XF)`,
    `59089 - South San Jose, CA (Almaden) (XF)`,
    `59089 - South San Jose, CA (XF)`,
    `59091 - Hayward, CA (XF)`,
    `59091 - Hayward, CA (XF)`,
    `59092 - Stockton, CA (XF)`,
    `59092 - Stockton, CA (XF)`,
    `59093 - Tacoma, WA (XF)`,
    `59093 - Tacoma, WA (XF)`,
    `59094 - Bonney Lake, CA (XF)`,
    `59095 - Florin Towne Center - Sacramento, CA (XF)`,
    `59098 - Highlands Ranch, CO (XF)`,
    `59099 - Madera, CA (XF)`,
    `59100 - Crystal, MN (XF)`,
    `59101 - Modesto, CA (XF)`,
    `59104 - Washington Square, OR (XF)`,
    `63000 - Events 1- Houston, TX - Truck`,
    `63100 - Event 2 -  Houston, TX (TRUCK)`,
    `63101 - Events 3 - Houston, TX - Truck`,
    `63102 - Events 4 - Houston, TX - Truck`,
    `64000 - BSR Events - Norcross, GA`,
    `69000 - California Tent Event, Livermore, CA`,
    `69001 - Mountain West Events CO. South - Truck`,
    `69002 - Portland Tent Event- Tigard, OR`,
    `69003 - Seattle Tent Event - Kirkland, WA`,
    `69004 - Twin Event`,
    `69111 - Mountain West Events Utah`,
    `69112 - Mountain West Events CO. North (TRUCK)`,
    `69113 - Mountain West Events New Mexico`,
    `69223 - Portland Mobile Cart - Tigard, OR`,
    `9000 - Freemont, IL (SX)`,
    `9100 - Washington, DC (SX)`,
]

const StoreLocator = ({ hideModal, customStyles }) => {
    const localStore = localStorage.getItem('store')
    const [store, setStore] = useState(localStore || stores[0])
    const [showModal, setShowModal] = useState(true)
    // Save cookie onSelect
    const handleClick = _ => {
        setShowModal(false)
        hideModal()
        setLocalStorage('store', store)
        window.dataLayer.push({'event':`storeSelected`, 'store': `${store}`, 'app_version': `${window.__version}`})
    }

    
    return(
        <ReactModal isOpen={showModal} style={customStyles}>
            <div className="grid place-items-center w-full h-full">
                <div className="grid place-items-center w-full h-1/2 self-center">
                    <h1 className="text-[2rem] mb-8 text-center max-w-[25ch]">Please select your store</h1>
                    {/* <select onChange={e => setStore(e.target.value)}>
                        {stores.map((x,i) => {
                            return <option key={i} value={x}>{x}</option>
                        })}
                    </select> */}
                    <ReactSelect2
                        data={stores}
                        value={store}
                        onSelect={e => setStore(e.target.value)}
                        options={{
                            allowClear: true,
                            placeholder: 'Select your store',
                          }}
                    ></ReactSelect2>
                    <button disabled={store === ''} onClick={_ => handleClick()}>Select</button>
                </div>
            </div>
        </ReactModal>

    )
}

export default StoreLocator