import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import { getStore } from "../Utills";
import CloseButton from "./Close";

const Learn = ({ hideModal, customStyles }) => {
    const { t, i18n } = useTranslation()
    return (
        <ReactModal isOpen style={customStyles}>
            <div className="relative min-w-full min-h-full bg-contain bg-no-repeat bg-bottom" style={{ backgroundImage: "url('/imgs/modal_learn.png')" }}>
                <div className="absolute grid place-items-center inset-0 bottom-1/2">
                    <h1 className="font-x-bold text-[2rem] mb-8 text-center max-w-[25ch]">{t('Modal-Learn-h1')}</h1>
                </div>
                <div className="absolute right-4 top-4 cursor-pointer" onClick={hideModal}><CloseButton /></div>
                <div className="absolute bottom-8 left-16">
                    <QRCodeSVG className="rounded-xl" value={`https://ipadrewards.click/?language=${i18n.resolvedLanguage}&sessionId=${window._activeSession}&store=${encodeURIComponent(getStore())}`} includeMargin size={250} />
                </div>
            </div>

        </ReactModal>

    )
}

export default Learn