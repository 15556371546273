function uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

function getCookieValueByName(name) {
    var match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
    return match ? match[2] : "";
}

function getStore() {
    return getLocalStorage('store')
}

function getLocalStorage(name) {
   return window.localStorage.getItem(name)
}

function setLocalStorage(name, value) {
    window.localStorage.setItem(name, value)
}

export {
    uuidv4,
    getCookieValueByName,
    getStore,
    getLocalStorage,
    setLocalStorage
}