import React from "react";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import CloseButton from "./Close";

const Sports = ({ hideModal, customStyles }) => {
    const { t, i18n } = useTranslation()
    return(
        <ReactModal isOpen style={customStyles}>
            <div className="relative min-w-full min-h-full bg-contain bg-no-repeat bg-bottom" style={{backgroundImage: "url('/imgs/modal_sports.png')"}}>
                <div className="absolute grid place-items-center inset-0 bottom-1/2">
                    <h1 className="font-x-bold text-[2rem] mb-8 text-center max-w-[28ch]">{t('Modal-Sports-h1')}</h1>
                </div>
                <div className="absolute right-4 top-4 cursor-pointer" onClick={hideModal}><CloseButton /></div>
            </div>
        </ReactModal>

    )
}

export default Sports