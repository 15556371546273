import React, { useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import ReactModal from "react-modal";
import { useModal } from "react-modal-hook";
import Jimmy from "../Modals/Jimmy";
import Learn from "../Modals/Learn";
import Mobile from "../Modals/Mobile";
import Movies from "../Modals/Movies";
import Orlando from "../Modals/Orlando";
import Sports from "../Modals/Sports";
import {getStore} from "../Utills"

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: 'min(98vh, 95vw)',
      height: 'min(95vh, 95vw)',
      borderRadius: '1rem',
      padding: 'none'
    },
  };

const Rewards = ({props}) => {
    const {t, i18n} = useTranslation()
    ReactModal.setAppElement('#root');

    const [showModal002, hideModal002] = useModal(() => <Movies hideModal={hideModal002} customStyles={customStyles} />);
    const [showModal003, hideModal003] = useModal(() => <Mobile hideModal={hideModal003} customStyles={customStyles} />);
    const [showModal004, hideModal004] = useModal(() => <Sports hideModal={hideModal004} customStyles={customStyles} />);
    const [showModal005, hideModal005] = useModal(() => <Learn hideModal={hideModal005} customStyles={customStyles} />);
    const [showModal006, hideModal006] = useModal(() => <Orlando hideModal={hideModal006} customStyles={customStyles} />);
    const [showModal007, hideModal007] = useModal(() => <Jimmy hideModal={hideModal007} customStyles={customStyles} />);

    useEffect(() => {
        if(!window._modalClosers.find(x => x === hideModal002)) { window._modalClosers.push(hideModal002) }
        if(!window._modalClosers.find(x => x === hideModal003)) { window._modalClosers.push(hideModal003) }
        if(!window._modalClosers.find(x => x === hideModal004)) { window._modalClosers.push(hideModal004) }
        if(!window._modalClosers.find(x => x === hideModal005)) { window._modalClosers.push(hideModal005) }
        if(!window._modalClosers.find(x => x === hideModal006)) { window._modalClosers.push(hideModal006) }
        if(!window._modalClosers.find(x => x === hideModal007)) { window._modalClosers.push(hideModal007) }
        return () => {
            window._modalClosers = window._modalClosers.reduce((a,x) => { if(x !== hideModal002) { a.push(x) } return a }, [])
            window._modalClosers = window._modalClosers.reduce((a,x) => { if(x !== hideModal003) { a.push(x) } return a }, [])
            window._modalClosers = window._modalClosers.reduce((a,x) => { if(x !== hideModal004) { a.push(x) } return a }, [])
            window._modalClosers = window._modalClosers.reduce((a,x) => { if(x !== hideModal005) { a.push(x) } return a }, [])
            window._modalClosers = window._modalClosers.reduce((a,x) => { if(x !== hideModal006) { a.push(x) } return a }, [])
            window._modalClosers = window._modalClosers.reduce((a,x) => { if(x !== hideModal007) { a.push(x) } return a }, [])
        }
    }, [hideModal002,hideModal003,hideModal004,hideModal005,hideModal006,hideModal007])
    
    const sendModalEvent = modal => {
        window.dataLayer.push({'event':`modalView`, 'modal': `${modal}`, 'language': `${i18n.resolvedLanguage}`, 'store': `${getStore()}`, 'sessionId': `${window._activeSession}`, 'app_version': `${window.__version}`})
    }

    return(
    <div id="Rewards" className="slide">
        <div className="grid gap-4 place-items-center w-full">
            <div className="grid place-items-center w-4/5">
                <div className="col-start-1">
                    <p className="text-center font-x-bold text-5xl mb-4">{t('Rewards-h1')}</p>
                    <p className="text-lg text-center">{t('Rewards-h2')}</p>
                </div>
            </div>
            <div className="grid grid-cols-6 grid-rows-4 gap-1 landscape:w-4/5 portrait:w-11/12">
                <div className="relative tile bg-x-purple row-start-1 row-span-2 col-start-1 col-span-3 grid grid-cols-[.80fr_.60fr] place-items-center" onClick={_ => {sendModalEvent(`Jimmy Fallon`); showModal007()}}>
                    <div className="absolute -left-20 portrait:right-[50%] landscape:right-[38%] bottom-0 top-8">
                        <div className="bg-contain bg-no-repeat bg-left-bottom w-full h-full"  style={{backgroundImage: "url('/imgs/rt_tix.png')"}}></div>
                    </div>
                    <div className="relative col-start-1 row-start-1 self-end w-full h-full">
                    </div>
                    <div className="col-start-2 row-start-1 pr-4 pt-8">
                        <p className="font-x-bold text-xl leading-tight mb-4">{t('Rewards-t1')}</p>
                        <p className="font-x-bold text-[0.625rem]">{t('Rewards-s1')}</p>
                    </div>
                </div>
                <div className="tile bg-x-blue row-start-3 row-span-1 col-start-1 col-span-2 grid grid-cols-2 place-items-center" onClick={_ => {sendModalEvent(`Mobile`); showModal003()}}>
                    <div className="col-start-1 row-start-1 self-end"><img src="/imgs/rt_phone.png"/></div>
                    <div className="col-start-2 row-start-1 px-4">
                        <p className="font-x-bold text-xl leading-tight">{t('Rewards-t2')}</p>
                    </div>
                </div>
                <div className="tile bg-x-blue row-start-3 row-span-1 col-start-3 col-span-1 grid place-items-center"> <img src="/imgs/rt_logo.png"/></div>
                <div className="tile bg-x-green row-start-4 row-span-1 col-start-1 col-span-1 grid place-items-center"><img src="/imgs/rt_u_logo.png"/></div>
                <div className="tile bg-x-green row-start-4 row-span-1 col-start-2 col-span-2 grid grid-cols-2 place-items-center" onClick={_ => {sendModalEvent(`Universal`); showModal006()}}>
                    <div className="col-start-1 row-start-1 self-end w-full h-full bg-cover"  style={{backgroundImage: "url('/imgs/rt_resort.png')"}}></div>
                    <div className="col-start-2 row-start-1 px-4">
                        <p className="font-x-bold text-xl leading-tight">{t('Rewards-t3')}</p>
                    </div>
                </div>
                <div className="tile bg-x-yellow row-start-1 row-span-1 col-start-4 col-span-2 grid grid-cols-12 place-items-center bg-contain bg-no-repeat bg-right" onClick={_ => {sendModalEvent(`Movie Night`); showModal002()}} style={{backgroundImage: "url('/imgs/rt_movie_night.png')"}}>
                    <div className="col-start-1 col-span-7 row-start-1 pl-4">
                        <Trans>
                            <p className="font-x-bold text-xl leading-tight text-black">{t('Rewards-t4')}</p>
                        </Trans>
                    </div>
                </div>
                <div className="tile bg-x-yellow row-start-1 row-span-1 col-start-6 col-span-1 grid place-items-center"><img src="/imgs/rt_xfinity_logo.png"/></div>
                <div className="tile bg-x-orange row-start-2 row-span-1 col-start-4 col-span-1 grid place-items-center"><img src="/imgs/rt_sports_logo.png"/></div>
                <div className="tile bg-x-orange row-start-2 row-span-1 col-start-5 col-span-2 grid grid-cols-2 place-items-center" onClick={_ => {sendModalEvent(`Sports`); showModal004()}}>
                    <div className="col-start-2 row-start-1 self-end w-full h-full bg-cover"  style={{backgroundImage: "url('/imgs/rt_sports.png')"}}></div>
                    <div className="col-start-1 row-start-1 pl-4">
                        <p className="font-x-bold text-xl leading-tight">{t('Rewards-t5')}</p>
                    </div>
                </div>
                <div className="tile bg-x-red row-start-3 row-span-2 col-start-4 col-span-3 grid grid-cols-2 place-items-center" onClick={_ => {sendModalEvent(`Learn More`); showModal005()}}>
                <div className="col-start-2 row-start-1 self-end w-full h-full bg-contain bg-no-repeat bg-bottom mr-8"  style={{backgroundImage: "url('/imgs/rt_learn_more.png')"}}></div>
                    <div className="col-start-1 row-start-1 px-4">
                        <p className="font-x-bold text-2xl leading-tight max-w-[10ch]">{t('Rewards-t6')}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Rewards