import { useTranslation } from 'react-i18next';
import AttractLoop from './Screens/AttractLoop';
import HowItWorks from './Screens/HowItWorks';
import Intro from './Screens/Intro';
import Longer from './Screens/Longer';
import Outro from './Screens/Outro';
import Rewards from './Screens/Rewards';
import { useEffect, useRef } from 'react';
import { ModalProvider } from "react-modal-hook";
import { useIdleTimer } from 'react-idle-timer'
import useScrollSnapMod from './ScrollSnapMod';
import { uuidv4, getStore } from './Utills';
function App() {

  const scrollRef = useRef(null);
  let [scrollIndex, goToTarget] = useScrollSnapMod({ ref: scrollRef, duration: 100, delay: 500 });
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    window.dataLayer.push({'event':`languageChange`, 'language': lng, 'store': `${getStore()}`, 'sessionId': `${window._activeSession}`, 'app_version': `${window.__version}`})
  };

  useEffect(() => {
    window._activeSession = uuidv4()
    return () => {}
  }, [])
  
  useEffect(() => {
    if(scrollIndex === 1) {
      // we started
      window.dataLayer.push({'event':`sessionStart`, 'store': `${getStore()}`, 'sessionId': `${window._activeSession}`, 'app_version': `${window.__version}`})
    }
    return () => { }
  }, [scrollIndex])
  

  const onIdle = () => {
    // Scroll to top
    document.documentElement.scroll(0,0)
    // Close all modals
    window._modalClosers.forEach(x => { x() });
    window.dataLayer.push({'event':`sessionEnd`, 'store': `${getStore()}`, 'sessionId': `${window._activeSession}`, 'app_version': `${window.__version}`})
    window._activeSession = uuidv4()
  }

  const onActive = () => {
    window._activeSession = uuidv4()
    window.dataLayer.push({'event':`sessionStart`, 'store': `${getStore()}`, 'sessionId': `${window._activeSession}`, 'app_version': `${window.__version}`})
  }


  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    timeout: 120_000,
    throttle: 500
  })

  return (
    <ModalProvider>
      <div className="App" ref={scrollRef}>
        <AttractLoop />
        <Intro />
        <HowItWorks />
        <Rewards />
        <Longer />
        <Outro />
        <div className="fixed grid grid-cols-2 gap-4 top-0 right-0 p-4 text-xs">
          <button className="btn-lang" disabled={i18n.resolvedLanguage === 'en'} type="button" onClick={() => changeLanguage('en')}>
            {t('switch-en')}
          </button>
          <button className="btn-lang" disabled={i18n.resolvedLanguage === 'es'} type="button" onClick={() => changeLanguage('es')}>
            {t('switch-es')}
          </button>
        </div>
        <div className={`fixed bottom-0 right-4 p-4 ${scrollIndex === 5 ? `hidden`: ``}`}>
            <button className='btn-join-now px-8 font-x-bold' onClick={_ => {
              goToTarget(document.querySelector('#Outro'));
              window.dataLayer.push({'event':`joinButtonClicked`, 'store': `${getStore()}`, 'sessionId': `${window._activeSession}`, 'language':`${i18n.resolvedLanguage}`, 'app_version': `${window.__version}`})
            }}>{t('btn-join')}</button>
        </div>
      </div>
    </ModalProvider>
  );
}

export default App;
